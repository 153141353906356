import { fork, call, select } from 'redux-saga/effects';
import URL from 'url';
import * as qs from 'query-string';
import {
  takeRequestTrigger,
  route,
  withSession,
  getAPI,
  getHistory,
} from './utils';
import { request } from '@hum/common/src/ducks/sagas/util';
import {
  ActionType,
  adminCompanyUsersStateChanged,
  companyInvitationsRequestStateChanged,
  apiUserLogoutRequestStateChanged,
  CompanySettingsChanged,
} from '../actions';
import { AppState, Routes } from '../state';
import { API } from '../backend/api';
import { loadCompanySettings, loadCurrentCompany } from './utils/model';
import { loadCompanies } from './app';
import { History } from 'history';

export function* handleRoutes() {
  yield fork(handleCompanyUsers);
  yield fork(handleSubdomain);
  yield fork(handleInvestorCompanies);
  yield fork(handlePortfolioInvestorCompanies);
  yield fork(handleLogout);
}

function* handleCompanyUsers() {
  const api: API = yield getAPI();
  yield takeRequestTrigger(
    [
      route(Routes.MANAGE_TEAM),
      route(Routes.SETTINGS_FUNDING_PROFILE),
      ActionType.API_COMPANY_USER_ADDED,
      ActionType.API_COMPANY_USER_UPDATED,
      ActionType.API_COMPANY_USER_REMOVED,
    ],
    withSession(function* () {
      const result = yield call(loadCurrentCompany);
      yield request(adminCompanyUsersStateChanged, () => {
        return api.legacy.getCompanyUsers(result.data?.id);
      });
    })
  );
}

function* handleInvestorCompanies() {
  const api: API = yield getAPI();
  yield takeRequestTrigger(
    [
      route(Routes.INVITE_COMPANIES),
      ActionType.API_COMPANY_INVITATION_ADDED,
      ActionType.API_COMPANY_INVITATION_UPDATED,
      ActionType.API_COMPANY_INVITATION_REMOVED,
    ],
    withSession(function* () {
      const result = yield call(loadCurrentCompany);
      yield request(companyInvitationsRequestStateChanged, () => {
        return api.legacy.getCompanyInvitations(result.data?.id);
      });
    })
  );
}

function* handleSubdomain() {
  const history: History = yield getHistory();
  yield takeRequestTrigger(
    [route(Routes.SUBDOMAIN)],
    withSession(function* () {
      const result = yield call(loadCurrentCompany);
      yield call(loadCompanySettings, result.data);
    })
  );
  yield takeRequestTrigger(
    [ActionType.COMPANY_SETTINGS_CHANGED],
    withSession(function ({ payload: { subdomain } }: CompanySettingsChanged) {
      let { host, pathname, query, ...rest } = URL.parse(
        window.location.href,
        true
      );
      const developmentHostsRegex = /(localhost|frontend\-e2e)/;
      if (developmentHostsRegex.test(host!)) {
        history?.push(`${pathname}?${qs.stringify({ ...query, subdomain })}`);
      } else {
        host = subdomain + '.' + host!.replace(/\w+\.(\w+\.\w+)/, '$1');
        window.location.assign(
          URL.format({
            host,
            pathname,
            query,
            ...rest,
          })
        );
      }
    })
  );
}

function* handlePortfolioInvestorCompanies() {
  yield takeRequestTrigger(
    [route(Routes.PORTFOLIO_COMPANIES), route(Routes.PORTFOLIO_COMPANY)],
    withSession(function* () {
      const state: AppState = yield select();
      const payload = { filters: state.companyListFilters };

      // send filters as payload
      yield call(loadCompanies, payload.filters);
    })
  );
}

function* handleLogout() {
  const api: API = yield getAPI();
  yield takeRequestTrigger([route(Routes.LOGOUT)], function* () {
    yield request(apiUserLogoutRequestStateChanged, () =>
      api.auth.userLogout()
    );
  });
}
