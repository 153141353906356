import { fork, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  HistoryActionType,
  historyInitialized,
  historyChanged,
} from '../actions';
import { getHistoryState } from '@hum/common';
import noop from 'lodash/noop';
import { getHistory } from './utils';
import { History } from 'history';

/**
 * Handles location changes so that we can do side-effect & state things
 * in sagas & reducers.
 */

export function* handleHistory() {
  const history: History = yield getHistory();
  yield fork(watchPopstate);
  yield fork(watchRouterPush);

  if (typeof window !== 'undefined') {
    yield put(historyInitialized(getHistoryState(history)));
  }
}

function* watchPopstate() {
  const history: History = yield getHistory();
  if (typeof window === 'undefined') {
    return;
  }

  const chan = yield eventChannel((emit) => {
    const onLocationChange = () => {
      emit(1);
    };
    window.addEventListener('popstate', onLocationChange);
    window.addEventListener('hashchange', onLocationChange);
    const disposeHistoryChange = history?.listen(onLocationChange) || noop;
    return () => {
      disposeHistoryChange();
      window.removeEventListener('popstate', onLocationChange);
      window.removeEventListener('hashchange', onLocationChange);
    };
  });

  while (yield take(chan)) {
    yield put(historyChanged(getHistoryState(history)));
  }
}

function* watchRouterPush() {
  yield takeEvery(HistoryActionType.ROUTER_URL_PUSHED, onHistoryChange);
}

function onHistoryChange() {}
