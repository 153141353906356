import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

// https://github.com/captec/capital/issues/3710
const API_DATE_FORMAT = 'YYYY-MM-DD H:mm:ss.SSSSS';

// let's make so all or almost all the dates in our app are in the same format using this function
// date is from backend in the following format: created_at: 2020-05-20 18:23:21.000000
export const formatDate = (
  date: string,
  {
    asOfTime = false,
    hideTime = false,
    parseAsUTC = true,
    dateFormat = API_DATE_FORMAT,
  }: {
    asOfTime?: boolean;
    hideTime?: boolean;
    parseAsUTC?: boolean; // note: the server is returning UTC dates but not telling us they are...
    dateFormat?: string;
  } = {}
) => {
  const parsedDate = dayjs(date, dateFormat);

  // utc(true) necessary since it fixes safari issue
  const correctDate = parseAsUTC ? parsedDate.utc(true).local() : parsedDate;

  if (hideTime) {
    return correctDate.format('MMM D, YYYY');
  } else if (asOfTime) {
    return correctDate.format('MMM YYYY');
  }

  return correctDate.format('MMM D, YYYY, h:mm A');
};

/**
 * Create a human readable time difference that counts back from now.  Eg `Just now`, `1 hour ago`, ect
 * @param date
 * @param dateFormat
 * @param parseAsUTC
 */
export const formatDateFromNow = (
  date: string,
  {
    parseAsUTC = true,
    dateFormat = API_DATE_FORMAT,
  }: {
    parseAsUTC?: boolean; // note: the server is returning UTC dates but not telling us they are...
    dateFormat?: string;
  } = {}
) => {
  const parsedDate = dayjs(date, dateFormat);

  // utc(true) necessary since it fixes safari issue
  const correctDate = parseAsUTC ? parsedDate.utc(true).local() : parsedDate;

  return correctDate.fromNow();
};

/**
 * i.e.:
 *   10.192  => $10.19
 *   1200400 => $1.2 M
 */
function money(input: number | string | undefined | null, short = true) {
  if (input === undefined || input === null) {
    return undefined;
  }

  const amount = parseFloat(input as string);
  const magnitude = Math.abs(amount);

  // Normal formatting for amounts under $100
  if (magnitude < 100) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  }

  // Remove cents for amounts over $100
  if (magnitude < 1000000 || !short) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  // Format amounts up to 100 million with M to indicate millions, use up two fraction digits for
  // thousands
  if (magnitude < 100000000) {
    const output = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(amount / 1000000);

    return `${output}M`;
  }

  // Format amounts up to 1 billion with M to indicate millions, without fraction digits
  if (magnitude < 1000000000) {
    const output = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Math.trunc(amount / 1000000));
    return `${output}M`;
  }

  // Format amounts up to 1 trillion with B to indicate billions without any fraction digits
  if (magnitude < 1000000000000) {
    const output = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.trunc(amount / 1000000000));

    return `${output}B`;
  }

  const output = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount / 1000000000000);
  return `${output}T`;
}

export { money };
