/* eslint-disable no-shadow */

import { RequiredInsights } from '@hum/icm-app/src/components/types';
import {
  User,
  CompanyRevenueModel,
  CreditBoxReport,
  Company,
  CompanyType,
  LeverageableAssets,
  CompanyChart,
  CompanyContact,
  SyndicationInvestorDetails,
  SyndicationInvestmentTypes,
  SyndicationInvestmentRanges,
  CompanyBusinessType,
  Application,
  UtmParams,
  AuthProviderTypes,
  CompanyFileDocumentType,
  SyndicationCompanyDetails,
  CompanyPublicProfileData,
  CompanyFile,
} from '@hum/types';
import { HistoryState } from '@hum/common';
import { Result } from '@hum/common/src/ducks/state';
import { SelectOption, selectOptionsFromMap } from '@hum/legacy-ui';
import {
  OnboardingV4State,
  ONBOARDING_V4_INITIAL_STATE,
} from '@hum/icm-app/src/pages/onboarding-v4/ducks/state';
import { ConfettiAppState } from '@hum/icm-app/src/modules/confetti/ducks/state';
import { INITIAL_CONFETTI_APP_STATE } from '@hum/icm-app/src/modules/confetti/ducks';
import {
  AnalayticsAppState,
  INITIAL_ANALAYTICS_APP_STATE,
} from '@hum/icm-app/src/modules/analytics/ducks/state';
import { ManageInsightsSettingsResponse } from '@hum/icm-app/src/pages/admin/AdminAnalytics/ducks/actions';
import { ConnectorStatus } from '@hum/icm-app/src/backend/api/models/connectorModels';

// Values are prefixed with codat- to differentiate from other services
export enum CodatConnectorType {
  Quickbooks = 'quickbooks',
  QuickbooksDesktop = 'quickbooks_desktop',
  QuickbooksSandbox = 'quickbooks_sandbox',
  Netsuite = 'netsuite',
}

export enum ConnectorType {
  Kizen = 'kizen',
  ThreeDCart = 'threedcart',
  Checkbook = 'checkbook',
  CompanyHub = 'companyhub',
  Swell = 'swell',
  Exact = 'exact',
  Affirm = 'affirm',
  StoreHippo = 'storehippo',
  AgileCRM = 'agilecrm',
  Authorizenet = 'authorizenet',
  Billomat = 'billomat',
  Mixpanel = 'mixpanel',
  Paywhirl = 'paywhirl',
  Revcent = 'revcent',
  Xcart = 'xcart',
  Braintree = 'braintree',
  Clearbooks = 'clearbooks',
  Enigma = 'enigma',
  Fastbill = 'fastbill',
  Freshbooks = 'freshbooks',
  GoogleAnalytics = 'google_analytics',
  GoogleAdsense = 'google_adsense',
  Holded = 'holded',
  Klarna = 'klarna',
  MicrosoftDynamics = 'microsoft_dynamics',
  Liftoff = 'liftoff',
  Netsuite = 'netsuite',
  MX = 'mx',
  Nimble = 'nimble',
  Mailchimp = 'mailchimp',
  Paypal = 'paypal',
  Plaid = 'plaid',
  Quickbooks = 'quickbooks',
  Recurly = 'recurly',
  Shopify = 'shopify',
  Square = 'square',
  Stripe = 'stripe',
  StripeTest = 'stripe_test',
  Worldpay = 'worldpay',
  Xero = 'xero',
  Yodlee = 'yodlee',
  Finch = 'finch',
  ImportedFiles = 'imported_files',
}

export enum AirbyteConnectorType {
  netsuite = ConnectorType.Netsuite,
  quickbooks = ConnectorType.Quickbooks,
  xero = ConnectorType.Xero,
}

export enum ConnectorDataType {
  QuickbooksBalanceSheet = 'quickbooks/balance_sheet',
  QuickbooksIncomeStatement = 'quickbooks/income_statement',
  XeroBalanceSheet = 'xero/balance_sheet',
  XeroProfitAndLoss = 'xero/profit_and_loss',
  InterpretedBraintreeTransactions = 'interpreted/braintree/transactions',
  InterpretedPaypalTransactionData = 'interpreted/paypal/transaction_data',
  InterpretedShopifyOrders = 'interpreted/shopify/orders',
  InterpretedStripeBalanceTransactions = 'interpreted/stripe/balance_transactions',
  InterpretedUploadedFileCustomerTape = 'interpreted/uploaded_file/customer_tape',
  CodatNetsuiteProfitAndLoss = 'codat/netsuite/profit_and_loss',
  CodatNetsuiteBalanceSheet = 'codat/netsuite/balance_sheet',
  CodatQuickbooksSandboxProfitAndLoss = 'codat/quickbooks_sandbox/profit_and_loss',
  CodatQuickbooksSandboxBalanceSheet = 'codat/quickbooks_sandbox/balance_sheet',
  CodatQuickbooksProfitAndLoss = 'codat/quickbooks/profit_and_loss',
  CodatQuickbooksBalanceSheet = 'codat/quickbooks/balance_sheet',
}

export type CompanyDataAsset = {
  companyId: number;
  connectorTaskId: number;
  createdAt: string;
  createdBy: number;
  displayName: string;
  dataType: string;
  id: number;
  location: string;
  updatedAt: string;
};

export enum TargetDebtRaise {
  Between0And3M = 'between_0_and_3_m',
  Between3And5M = 'between_3_and_5_m',
  Between5And10M = 'between_5_and_10_m',
  Between10And25M = 'between_10_and_25_m',
  Between25And50M = 'between_25_and_50_m',
  Between50And100M = 'between_50_and_100_m',
  MoreThan100M = 'more_than_100_m',
}

export const TARGET_DEBT_RAISE = {
  [TargetDebtRaise.Between0And3M]: 'Less than $3M',
  [TargetDebtRaise.Between3And5M]: '$3M–$5M',
  [TargetDebtRaise.Between5And10M]: '$5M–$10M',
  [TargetDebtRaise.Between10And25M]: '$10M–$25M',
  [TargetDebtRaise.Between25And50M]: '$25M–$50M',
  [TargetDebtRaise.Between50And100M]: '$50M–$100M',
  [TargetDebtRaise.MoreThan100M]: '$100M or more',
};

export enum FundraisingTimeFrameMonths {
  Between0and3 = 'between_0_and_3_months',
  Between3and6 = 'between_3_and_6_months',
  Between6and12 = 'between_6_and_12_months',
  MoreThan12 = 'more_than_12_months',
  NoCurrentPlansToStartFundraising = 'no_current_plans_to_start_fundraising',
}

export const FUNDRAISING_TIME_FRAME_MONTHS_OPTIONS = selectOptionsFromMap({
  [FundraisingTimeFrameMonths.Between0and3]: 'Less than 3 months',
  [FundraisingTimeFrameMonths.Between3and6]: '3 - 6 months',
  [FundraisingTimeFrameMonths.Between6and12]: '6 - 12 months',
  [FundraisingTimeFrameMonths.MoreThan12]: 'More than 12 months from now',
  [FundraisingTimeFrameMonths.NoCurrentPlansToStartFundraising]:
    'No current plans to start fundraising',
});

export enum ConnectorCategory {
  EnterpriseResourcePlanning = 'EnterpriseResourcePlanning',
  PointOfSale = 'PointOfSale',
  Bank = 'Bank',
  Other = 'Other',
}

export const CONNECTOR_LABELS: Record<
  ConnectorType | CodatConnectorType,
  string
> = {
  [ConnectorType.Swell]: 'Swell',
  [ConnectorType.Kizen]: 'Kizen',
  [ConnectorType.ThreeDCart]: '3dcart',
  [ConnectorType.Checkbook]: 'Checkbook',
  [ConnectorType.StoreHippo]: 'StoreHippo',
  [ConnectorType.CompanyHub]: 'CompanyHub',
  [ConnectorType.Exact]: 'Exact',
  [ConnectorType.Affirm]: 'Affirm',
  [ConnectorType.AgileCRM]: 'Agile CRM',
  [ConnectorType.Authorizenet]: 'Authorize.Net',
  [ConnectorType.Billomat]: 'Billomat',
  [ConnectorType.Mixpanel]: 'Mixpanel',
  [ConnectorType.Revcent]: 'RevCent',
  [ConnectorType.Xcart]: 'X-Cart',
  [ConnectorType.Braintree]: 'Braintree',
  [ConnectorType.Clearbooks]: 'Clearbooks',
  [ConnectorType.GoogleAnalytics]: 'Google Analytics',
  [ConnectorType.GoogleAdsense]: 'Google Adsense',
  [ConnectorType.Enigma]: 'Enigma',
  [ConnectorType.Fastbill]: 'Fastbill',
  [ConnectorType.Freshbooks]: 'Freshbooks',
  [ConnectorType.Holded]: 'Holded',
  [ConnectorType.Klarna]: 'Klarna',
  [ConnectorType.Liftoff]: 'Liftoff',
  [ConnectorType.MicrosoftDynamics]: 'Microsoft Dynamics',
  [ConnectorType.Paypal]: 'Paypal',
  [ConnectorType.Paywhirl]: 'PayWhirl',
  [ConnectorType.Plaid]: 'Plaid',
  [ConnectorType.MX]: 'MX',
  [ConnectorType.Netsuite]: 'NetSuite',
  [ConnectorType.Nimble]: 'Nimble',
  [ConnectorType.Mailchimp]: 'Mailchimp',
  [ConnectorType.Quickbooks]: 'QuickBooks',
  [ConnectorType.Recurly]: 'Recurly',
  [ConnectorType.Shopify]: 'Shopify',
  [ConnectorType.Square]: 'Square',
  [ConnectorType.Stripe]: 'Stripe',
  [ConnectorType.StripeTest]: 'Stripe Test',
  [ConnectorType.Worldpay]: 'Worldpay',
  [ConnectorType.Xero]: 'Xero',
  [ConnectorType.Yodlee]: 'Yodlee',
  [ConnectorType.Finch]: 'Finch',
  [ConnectorType.ImportedFiles]: 'Imported Files',
  [CodatConnectorType.QuickbooksDesktop]: 'Quickbooks Desktop',
  [CodatConnectorType.QuickbooksSandbox]: 'Quickbooks Sandbox',
};

export const CONNECTOR_CATEGORIES_MAP: Record<
  ConnectorType | CodatConnectorType,
  ConnectorCategory
> = {
  [ConnectorType.Swell]: ConnectorCategory.PointOfSale,
  [ConnectorType.Kizen]: ConnectorCategory.PointOfSale,
  [ConnectorType.Checkbook]: ConnectorCategory.PointOfSale,
  [ConnectorType.CompanyHub]: ConnectorCategory.PointOfSale,
  [ConnectorType.ThreeDCart]: ConnectorCategory.PointOfSale,
  [ConnectorType.StoreHippo]: ConnectorCategory.PointOfSale,
  [ConnectorType.Exact]: ConnectorCategory.PointOfSale,
  [ConnectorType.Affirm]: ConnectorCategory.PointOfSale,
  [ConnectorType.AgileCRM]: ConnectorCategory.PointOfSale,
  [ConnectorType.Authorizenet]: ConnectorCategory.PointOfSale,
  [ConnectorType.Billomat]: ConnectorCategory.PointOfSale,
  [ConnectorType.Paywhirl]: ConnectorCategory.PointOfSale,
  [ConnectorType.Nimble]: ConnectorCategory.PointOfSale,
  [ConnectorType.Revcent]: ConnectorCategory.PointOfSale,
  [ConnectorType.Mailchimp]: ConnectorCategory.PointOfSale,
  [ConnectorType.Xcart]: ConnectorCategory.PointOfSale,
  [ConnectorType.Braintree]: ConnectorCategory.PointOfSale,
  [ConnectorType.Clearbooks]: ConnectorCategory.PointOfSale,
  [ConnectorType.GoogleAnalytics]: ConnectorCategory.PointOfSale,
  [ConnectorType.GoogleAdsense]: ConnectorCategory.PointOfSale,
  [ConnectorType.Enigma]: ConnectorCategory.PointOfSale,
  [ConnectorType.Fastbill]: ConnectorCategory.PointOfSale,
  [ConnectorType.Freshbooks]: ConnectorCategory.EnterpriseResourcePlanning,
  [ConnectorType.Holded]: ConnectorCategory.PointOfSale,
  [ConnectorType.Klarna]: ConnectorCategory.PointOfSale,
  [ConnectorType.Liftoff]: ConnectorCategory.PointOfSale,
  [ConnectorType.MicrosoftDynamics]: ConnectorCategory.PointOfSale,
  [ConnectorType.Paypal]: ConnectorCategory.PointOfSale,
  [ConnectorType.Plaid]: ConnectorCategory.Bank,
  [ConnectorType.Mixpanel]: ConnectorCategory.PointOfSale,
  [ConnectorType.Netsuite]: ConnectorCategory.EnterpriseResourcePlanning,
  [ConnectorType.MX]: ConnectorCategory.PointOfSale,
  [ConnectorType.Quickbooks]: ConnectorCategory.EnterpriseResourcePlanning,
  [ConnectorType.Recurly]: ConnectorCategory.PointOfSale,
  [ConnectorType.Shopify]: ConnectorCategory.PointOfSale,
  [ConnectorType.Square]: ConnectorCategory.PointOfSale,
  [ConnectorType.Stripe]: ConnectorCategory.PointOfSale,
  [ConnectorType.StripeTest]: ConnectorCategory.PointOfSale,
  [ConnectorType.Worldpay]: ConnectorCategory.PointOfSale,
  [ConnectorType.Xero]: ConnectorCategory.EnterpriseResourcePlanning,
  [ConnectorType.Yodlee]: ConnectorCategory.PointOfSale,
  [ConnectorType.Finch]: ConnectorCategory.EnterpriseResourcePlanning,
  [ConnectorType.ImportedFiles]: ConnectorCategory.Other,
  [CodatConnectorType.Quickbooks]: ConnectorCategory.EnterpriseResourcePlanning,
  [CodatConnectorType.QuickbooksDesktop]:
    ConnectorCategory.EnterpriseResourcePlanning,
  [CodatConnectorType.QuickbooksSandbox]:
    ConnectorCategory.EnterpriseResourcePlanning,
  [CodatConnectorType.Netsuite]: ConnectorCategory.EnterpriseResourcePlanning,
};

export const CONNECTOR_CATEGORY_LABELS: Record<ConnectorCategory, string> = {
  [ConnectorCategory.EnterpriseResourcePlanning]: 'Account System',
  [ConnectorCategory.PointOfSale]: 'Point of Sale',
  [ConnectorCategory.Bank]: 'Bank',
  [ConnectorCategory.Other]: 'Other',
};

export enum ConnectorSource {
  Capital = 'capital',
  Airbyte = 'airbyte',
  Codat = 'codat',
}

export enum ConnectorHealthStatus {
  Pending = 'pending',
  InitialPullInProgress = 'initial_pull_in_progress',
  PullInProgress = 'pull_in_progress',
  Critical = 'critical',
  Recovering = 'recovering',
  Healthy = 'healthy',
  Stale = 'stale',
}

export enum DocuSignConnectorStatus {
  Connected = 'connected',
  Expired = 'expired',
}

export enum CapitalMachineModelStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Success = 'success',
  Failed = 'failed',
}

export type Tenant = {
  id: string;
  tenant_id: string;
  name: string;
};

export type Connector = {
  id: number;
  name: string;
  companyId: number;
  connectorType: ConnectorType | CodatConnectorType;
  status: ConnectorStatus; // connection status to the service, the company can see this
  health: ConnectorHealthStatus; // status of the data-pulling process, only admins can should see this, TODO: eventually remove this one, it's a duplicate of healthStatus
  healthStatus: ConnectorHealthStatus; // status of the data-pulling process, only admins can should see this
  dataPullInProgress: boolean;
  lastSuccessfulPullAt: string;
  mostRecentUnderlyingData: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  tenants?: Tenant[];
  authProviderId: number;
  eta?: string;
  connectorSource: ConnectorSource;
};

export type DocusignConnector = {
  expiresAt: string;
  updatedAt: string;
  status: DocuSignConnectorStatus;
};

export enum EnvelopeStatus {
  Created = 'created',
  Sent = 'sent',
  Delivered = 'delivered',
  Signed = 'signed',
  Completed = 'completed',
  Declined = 'declined',
  Voided = 'voided',
  Downloaded = 'downloaded',
  PendingDownload = 'pending_download',
}

export type Envelope = {
  companyId: number;
  createdAt: number;
  updatedAt: number;
  envelopeId: number;
  sourceFileId: number;
  status: EnvelopeStatus;
};

export enum CompanyCharTabName {
  preTermSheet = 'pre_term_sheet',
  diligence = 'diligence',
  servicing = 'servicing',
  audit = 'audit',
  historicalData = 'historical_data',
  projection = 'projection',
  syndication = 'syndication',
  portfolioMonitoring = 'portfolio_monitoring',
  syndicationPreIntro = 'syndication_pre_intro',
  portfolioAnalytics = 'portfolio_analytics',
  financialAnalysis = 'financial_analysis',
  revenueDetails = 'revenue_details',
  customerAnalysis = 'customer_analysis',
  cohortAnalysis = 'cohort_analysis',
}

export type PlotArgs = {
  data: [];
  layout: object;
};

export type InsightsCharts = {
  financial_analysis?: CompanyChart[];
  customer_analysis?: CompanyChart[];
  cohort_analysis?: CompanyChart[];
};

export type StoredFile = {};

export type MarkdownText = string;

// Including but not limited to...
export enum CompanyTag {
  InsightsReady = 'insights_ready',
  HideProcessingDataAlert = 'hide_processing_data_alert',
  DataComplete = 'data_complete',
  DataLabeled = 'data_labeled',
  FunnelPortfolio = 'funnel_portfolio',
  FunnelSyndication = 'funnel_syndication',
  FunnelPrincipal = 'funnel_principal',
  FunnelSortingHat = 'funnel_sorting_hat',
  WaitingForData = 'waiting_for_data',
  ScPortfolio = 'sc_portfolio',
}

export type CompanySettings = {
  subdomain: string;
};

export type CompanyInvitation = {
  id: number;
  owner: CompanyContact;
  company: Company;
};

export type MNDAPayloadType = {
  firstName: string;
  lastName: string;
  role: string;
};

export type CompanyPublicData = {
  showPreIntroCharts: boolean;
} & Company;

export type Pagination = {
  pageCount: number;
  results: number;
  currentPage: number;
  pageSize: number;
};

export type CompanyMatchInvestor = {
  uuid: string;
  name: string;
  companyId: number;
  investorUserId: number;
  description: string;
  website: string;
  location: string;
  syndication_investment_business_types: SelectOption[];
  investmentTypes: SelectOption[];
  investmentRanges: SelectOption[];
};

export enum SyndicationMatchStatus {
  Selected = 'selected',
  Sent = 'sent',
  Matched = 'matched',
  NoMatched = 'no_matched',
  TermSheetSigned = 'term_sheet_signed',
  Closed = 'closed',
  Failed = 'failed',
  IntroNeedsApproval = 'intro_needs_approval',
  Passed = 'passed',
}

// used for company and investor choice at the company/investor level
export enum SyndicationMatchActionStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Passed = 'passed',
}

export type SyndicationMatchOutReasons = {
  createdAt: string;
  id: number;
  matchOutReasonType: string;
  reason: string;
  updatedAt: string;
  checked?: boolean;
};

export type SyndicationMatch = {
  id: number;
  uuid: string;
  company: Company;
  status: SyndicationMatchStatus;
  companyActionStatus: SyndicationMatchActionStatus;
  syndicationInvestorActionStatus: SyndicationMatchActionStatus;
  syndicationInvestor: SyndicationInvestorDetails;
  companyActionUpdatedAt?: string;
  companyId: number;
  companySentAt?: string;
  companyViewedAt?: string;
  createdAt?: string;
  syndicationInvestorActionUpdatedAt?: string;
  syndicationInvestorId: number;
  syndicationInvestorSentAt?: string;
  syndicationInvestorViewedAt?: string;
  updatedAt?: string;
  optOutOfFee: boolean;
};

export type SyndicationOnboardingMatch = {
  uuid: string;
  description: string;
};

export type SyndicationLegalTerms = {
  tos?: string;
};

export type SyndicationOnboardingResult = {
  description: string;
  match: string;
  name: string;
  syndicationInvestorId: number | null;
  uuid: string;
  companyId?: number;
};

export type SyndicationMatchEvent = {
  events: { date: string; name: string }[];
  views: {
    company: number;
    syndicationInvestor: number;
  };
};

export type SyndicationMatchProfile = {
  companyId: number;
  name: string;
  website?: string;
  description?: string;
  type: CompanyType[];
  businessType?: CompanyBusinessType;
  country?: string;
  location?: string;
  investmentAmount?: number | string | null;
  investmentTypes: {
    label: string;
    value: SyndicationInvestmentTypes;
  }[];
  investmentRanges: {
    label: string;
    value: SyndicationInvestmentRanges;
  }[];
  firstName: string;
  lastName: string;
  position?: string;
  email: string;
  phone?: string;
};

// Portfolio monitoring
export enum PorfolioMonitoringInviteStatus {
  Created = 'created', // invite is in portfolio monitoring invites table
  Pending = 'pending', // investor clicked "invite" from onboarding send email
  Sent = 'sent', // Slawson sent an email to the company
  Viewed = 'viewed', // they clicked the email
  Accepted = 'accepted', // they created a profile if they're new OR they accepted the "match" if they already were in TCM
}

//Portfolio Monitoring Dashboard
export type PortfolioMonitoringDashboard = {
  portfolioMonitoringDashboard: {
    ltmRevenue: number;
    cashOnHand: number;
    lastMonthEbitda: number;
  }[];
};

export type PortfolioMonitoringConnectorStatus = {
  id: number;
  name: string;
  status: string;
  contactName: string;
  contactEmail: string;
};

export type PortfolioCompanies = Company[];

export type PortfolioMonitoringInvite = {
  id: number;
  investorCompanyID: number;
  companyID?: number;
  companyName: string;
  companyUrl: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPosition: string;
  investedCapital?: number;
  dateOfFirstInvestment: string | null;
  status: PorfolioMonitoringInviteStatus;
  statusUpdatedAt: string;
};

export type PortfolioMonitoringInviteForm = Pick<
  PortfolioMonitoringInvite,
  | 'companyName'
  | 'companyUrl'
  | 'contactFirstName'
  | 'contactLastName'
  | 'contactEmail'
  | 'contactPosition'
  | 'investedCapital'
  | 'dateOfFirstInvestment'
>;

export type PortfolioMonitoringStatusForm = Pick<
  PortfolioMonitoringInvite,
  'status'
>;

export type PortfolioMonitoringOnboarding = {
  showOnboarding: boolean;
};

export type PortfolioMonitoringCompanyInviteUrl = {
  inviteUrl: string;
};

// Principal Investment
export enum PrincipalInvestmentStatus {
  Claimed = 'claimed',
  Term_sheet_signed = 'term_sheet_signed',
  Closed = 'closed',
  Success = 'success',
  Failed = 'failed',
}

export type PrincipalInvestment = {
  id: number;
  status: PrincipalInvestmentStatus;
  interestedBalanceSheetPartners: InterestedBalanceSheetPartner[];
};

export type InterestedBalanceSheetPartner = {
  id: number;
  status: BalanceSheetPartnerStatus;
  balanceSheetPartner: BalanceSheetPartner;
  updatedAt: string;
};

export enum BalanceSheetPartnerStatus {
  Interested = 'interested',
  NotInterested = 'not_interested',
}

export type BalanceSheetPartner = {
  id: number;
  company: Partial<Pick<Company, 'id' | 'name'>>;
  status: BalanceSheetPartnerStatus;
};

export type BalanceSheetPartnerForm = {
  company: Partial<
    Pick<Company, 'name' | 'website' | 'description' | 'publicDescription'>
  >;
  contact: Partial<Pick<CompanyContact, 'email' | 'firstName' | 'lastName'>>;
};

export type CanCompanyBeAddedToInvestorSubdomainsInvestigation = {
  canBeAdded: boolean | undefined;
  companyName: string | undefined;
};

export type CompanyTOSByToken = {
  acceptedTos: boolean | undefined;
  reviewCompanyCommunications: boolean | undefined;
};
// Note that a form is based on _user_ input, so all fields
// need to be nullable. (CC)

export type SignUpForm = {
  contact: Partial<CompanyContact>;
  company: Partial<
    Pick<
      Company,
      | 'name'
      | 'website'
      | 'businessType'
      | 'country'
      | 'description'
      | 'publicDescription'
    >
  >;
  application: Partial<
    Pick<
      Application,
      'yearlyRevenue' | 'monthsOfRunway' | 'recordSystem' | 'paymentProcessor'
    >
  >;
  role?: {
    name: CompanyType;
  };
  utmParams?: Partial<
    Pick<
      UtmParams,
      'utmMedium' | 'utmSource' | 'utmCampaign' | 'utmTerm' | 'utmContent'
    >
  >;
  authProvider?: AuthProviderTypes;
  subdomain?: string;
};

export type SignUpV5Form = {
  contact: Partial<
    Pick<CompanyContact, 'firstName' | 'lastName' | 'email' | 'position'>
  >;
  company: Partial<
    Pick<Company, 'name' | 'website' | 'state' | 'pendingMndaSignOffline'>
  >;
  application: Partial<
    Pick<
      Application,
      | 'yearFounded'
      | 'selfReportedIndustry'
      | 'revenueModel'
      | 'fundraisingTimeFrame'
      | 'fundingType'
      | 'useOfFunds'
      | 'lastFundingRound'
      | 'lastRaised'
      | 'equityRaised'
      | 'latestPostMoneyValuation'
    >
  >;
};

export type FileInput = {
  documentType: CompanyFileDocumentType;
  name: string;
  source?: { url: string; path: string };
  type: string | null | undefined;
  isExternal?: boolean;
  meta?: Record<string, any>;
};

export type FilePresignedPost = {
  fields: {
    AWSAccessKeyId: string;
    'Content-Type': string;
    acl: string;
    key: string;
    policy: string;
    signature: string;
  };
  url: string;
};

export enum FilterType {
  Options = 'Options',
  Checkbox = 'Checkbox',
  Toggle = 'Toggle',
  Text = 'Text',
}

type BaseFilter<TFilterType extends FilterType> = {
  key: string;
  label?: string;
  type: TFilterType;
};

export type CheckboxFilter = {
  checked: boolean;
} & BaseFilter<FilterType.Checkbox>;

export type TextFilter = {
  value: string;
} & BaseFilter<FilterType.Text>;

export type OptionsFilter = {
  selected: string;
  options: { label: string; value: string }[];
} & BaseFilter<FilterType.Options>;

export type ToggleFilter = {
  selected: string;
  options: { label: string; value: string }[];
} & BaseFilter<FilterType.Toggle>;

export type Filter = CheckboxFilter | TextFilter | ToggleFilter | OptionsFilter;

export type FilterCategory = {
  key: string;
  label?: string;
  filters: Filter[];
};

export type QueryStringEntry = {
  value:
    | OptionsFilter['selected']
    | ToggleFilter['selected']
    | CheckboxFilter['checked']
    | TextFilter['value'];
};

export type PaypalClientSecret = {
  clientId: string;
  clientSecret: string;
};

export type CurrentCompany = Pick<Company, 'id' | 'name'>;

export type CompanySignUpV5 = {
  id: number;
};

export type Login = {
  email: string;
  password: string;
};

export type PasswordReset = {
  password: string;
  acceptedToS?: boolean;
  acceptedCommunications?: boolean;
};

export type MatchTocVersion = {
  matchTocVersion: string;
};

export type InvitationRedemption = {
  isRedeemed: boolean;
};

export type MatchResponse = {
  isMatch: boolean;
};

export type SyndicationMatchProfile2 = {
  company: Company;
  uuid: string;
  status: string;
};

export type CompanyDetailsForInvestorInterest = {
  companyName: string;
  companyDescription: string;
};

export enum LogoPreset {
  COLOR = 'full_color',
  WHITE = 'white_logo',
  BLACK = 'black_logo',
}

export type Branding = {
  primaryColor?: string;
  preset?: LogoPreset;
  logoFileId?: number | null;
  logoFileUrl?: string;
  editedLogoFileId?: number | null;
  editedLogoFileUrl?: string;
  name?: string;
  subdomain?: string;
};

export type CompanyPortfolioInvite = {
  id: number;
  investorName: string;
};

export enum CompanyPortfolioInviteAction {
  Accept = 'accept',
  Reject = 'reject',
}
export type FinancialDetailsPayload = {
  fundraisingTimeFrame: string;
  targetRaise?: string; // FF: OnboardingV4TargetCapitalRaise - Remove once flag is removed
  lastValuation: number;
  totalEquityRaised: string;
  useOfFunds: string;
  leverageableAssets: [LeverageableAssets] | [];
};

export type UserInfoPayload = {
  firstName: string;
  lastName: string;
  companyRole: string;
};

export enum SyndicationSectionDeals {
  //----DEPRECATED----
  new_deals = 'new_deals',
  live_deals = 'live_deals',
  archive_deals = 'archive_deals',
  //----DEPRECATED----

  newDeals = 'new_deals',
  liveDeals = 'live_deals',
  archiveDeals = 'archive_deals',
}

export enum SyndicationSectionDealsParams {}

export enum AnalysisStatus {
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
}

export type CompanyAnalysis = {
  id: number;
  companyId: number;
  asOf: string;
  createdAt: string;
  status: AnalysisStatus;
  chartCount: number;
  warningCount: number;
  airflowUrl: string;
};

export type CompanyNotices = {
  [NoticeName.OnboardingTour]?: boolean;
  [NoticeName.PorfolioInvestorGetStarted]?: boolean;
  [NoticeName.CompanyActivationClickedLetsGo]?: boolean;
  [NoticeName.CompanyActivationDismissedBanner]?: boolean;
  [NoticeName.CompanyActivationDismissedGetStarted]?: boolean;
  [NoticeName.CompanyActivationDataRoomDismissed]?: boolean;
  [NoticeName.CompanyActivationConnectorsDismissed]?: boolean;
  [NoticeName.CompanyActivationFundingProfileDismissed]?: boolean;
  [NoticeName.CompanyActivationIntroductionRequestDismissed]?: boolean;
  [NoticeName.CompanyActivationInvestorPreferencesDismissed]?: boolean;
  [NoticeName.CompanyActivationFundingOpportunitiesDismissed]?: boolean;
};

export enum EmailAddressAcceptability {
  allowed = 'ALLOWED',
  alreadyExists = 'ALREADY_EXISTS',
  isBlackListed = 'IS_BLACK_LISTED',
  invalidEmailAddress = 'INVALID_EMAIL_ADDRESS',
}

export type CompanyActivationNotices = {
  enabled: boolean;
  noticeName: NoticeName;
};

export type CompanyAnalysisWarning = {
  id: number;
  createdAt: string;
  warningType: string;
  message: string;
  detailedMessage: string;
  meta: string;
};

export type BlockPermissionsPayloadType = {
  permissions_set_name: string;
  enable: boolean;
};

export type MatchOutReasonsPayloadType = {
  passReasonIds: number[];
  otherDescription: string;
};

export type SyndicationPermissions = {
  dataroom?: boolean;
  financial_analysis?: boolean;
};

export type AdminMatchSyndicationPermissions = SyndicationPermissions & {
  investor_user_id?: number;
};

// the key will be match ids
export type AdminSyndicationPermissions = {
  [key: number]: AdminMatchSyndicationPermissions;
};

export type SyndicationTransactionDocumentUpload = {
  message?: string;
};

export type SyndicationCompanyForm = {
  syndicationCompany: Pick<
    SyndicationCompanyDetails,
    'investmentAmount' | 'yearlyRevenueForSyndication'
  > & {
    investmentTypes: SelectOption[];
  };
  company: Pick<
    Company,
    'businessTypeForSyndication' | 'description' | 'publicDescription'
  >;
};

export type AppState = {
  session: Result<User>;
  userSetPasswordResult: Result<User>;

  branding: Result<Branding>;
  whitelabeling: Result<Branding>;
  currentCompanyUsers: Result<CompanyContact[]>;
  currentCompanyInvitations: Result<CompanyInvitation[]>;
  connectors: Result<Connector[]>;
  charts: Result<CompanyChart[]>;
  currentCompanyUser: Result<CompanyContact>;
  currentCompany: Result<Company>;
  currentCompanySettings: Result<CompanySettings>;
  currentCompanyDataAssets: Result<CompanyDataAsset[]>;
  syndicationMatch: Result<SyndicationMatchProfile2>;

  companies: Result<{ companies: Company[]; pagination: Pagination }>;
  companyListFilters: FilterCategory[];
  currentCompanyFiles: Result<CompanyFile[]>;
  envelope: Result<Envelope>;
  docusignConnector: Result<DocusignConnector>;

  // teaser page
  teaserData: Result<CompanyPublicProfileData>;

  // admin tabs
  companyUsers: Result<User[]>;

  // manually defined -- probably via Cypress
  investorCompanies: Company[];
  investorAvailableCompanies: Company[];
  companyInvestorList: Result<Company[]>;

  history?: HistoryState;

  // Syndication admin
  syndicationRecommendations: Result<SyndicationMatch[]>;
  syndicationMatches: Result<SyndicationMatch[]>;
  syndicationMatchEvents: Record<any, Result<SyndicationMatchEvent>>;
  syndicationMatchOutReasons: Result<SyndicationMatchOutReasons[]>;
  // Syndication portal
  syndicationMatchesForGuest: Result<SyndicationOnboardingMatch[]>;
  syndicationPreIntroCharts: Result<CompanyChart[]>;
  syndicationInsightsCharts: Result<InsightsCharts>;
  syndicationPermissions: Result<SyndicationPermissions>;
  adminSyndicationPermissions: Result<AdminSyndicationPermissions>;
  syndicationInvestorDetails: Result<CompanyMatchInvestor>;

  // Syndication onboarding
  syndicationOnboardingMatches: Result<SyndicationOnboardingMatch[]>;
  syndicationOnboardingResults: Result<SyndicationOnboardingResult[]>;
  syndicationExistingProofFiles: Result<CompanyFile[]>;
  syndicationMatchTocVersion: Result<MatchTocVersion>;
  invitationRedemption: Result<InvitationRedemption>;
  companyDetailsForInvestorInterest: Result<CompanyDetailsForInvestorInterest>;
  syndicationConditionsNeedingAcceptance: Result<SyndicationLegalTerms>;
  syndicationMatchOutOfFeeFiles: Result<CompanyFile[]>;
  matchResponse: Result<MatchResponse>;
  // filters in admin requires connectors list from backend
  availableConnectors: Result<string[]>;

  // syndication
  currentMatchProfile: Result<SyndicationMatchProfile>;
  currentMatchProfileAnalytics: Result<CompanyChart[]>;
  syndicationMatchDataRoom: Result<CompanyFile[]>;
  syndicationTransactionDocumentUpload: Result<SyndicationTransactionDocumentUpload>;

  // Principal Investment
  principalInvestments: Result<PrincipalInvestment[]>;
  principalInvestmentAvailableBalanceSheetPartners: Result<
    BalanceSheetPartner[]
  >;

  // portfolio monitoring
  investorPortfolioInvites: Result<PortfolioMonitoringInvite[]>;
  companyPortfolioInvites: Result<CompanyPortfolioInvite[]>;
  showPortfolioOnboarding: Result<PortfolioMonitoringOnboarding>;
  portfolioCompanyInviteUrl: Result<PortfolioMonitoringCompanyInviteUrl>;

  portfolioMonitoringDashboard: Result<PortfolioMonitoringDashboard>;
  portfolioCompanies: Result<PortfolioCompanies>;
  portfolioMonitoringCompaniesConnectorStatus: Result<
    PortfolioMonitoringConnectorStatus[]
  >;
  // onboarding
  companySignUpAllowedEmail: Result<{ isAllowed: boolean }>;
  companySignUp: Result<{ application: Application; company: Company }>;
  canCompanyBeAddedToInvestorSubdomainsInvestigation: Result<CanCompanyBeAddedToInvestorSubdomainsInvestigation>;
  companyNotices: Result<CompanyNotices>;
  companyActivationNotices: Result<CompanyActivationNotices[]>;
  tosByToken: Result<CompanyTOSByToken>;

  // company analyses
  companyAnalyses: Result<CompanyAnalysis[]>;
  companyAnalysisWarnings: Result<CompanyAnalysisWarning[]>;
  selectedCompanyAnalysisId?: number;

  // company public profile
  companyPublicProfile: Result<CompanyPublicProfileData>;

  // page states
  onboardingv4: OnboardingV4State;
  companySignUpV5: Result<CompanySignUpV5>;
  getStartedDismissable: boolean;
  getStartedDismissed: boolean;

  // analytics charts
  analyticsCharts: Result<CompanyChart[]> | null;

  // Manage Insights Settings
  manageInsightsSettings: ManageInsightsSettingsResponse[];
  showCompanyFinancialSummary: boolean;
  showQuickBooksRawData: boolean;
  //investor analytics
  isDownloadingCompanyFinancialsFile: {
    docType: CompanyFileDocumentType | undefined;
    status: boolean;
  };
  //credit box
  creditBoxReport: Result<CreditBoxReport[]>;
  isDownloadingCreditBoxReport: boolean;
} & ConfettiAppState &
  AnalayticsAppState;

export const INITIAL_MANAGE_INSIGHTS_SETTINGS_STATE: ManageInsightsSettingsResponse[] = [];

export const INITIAL_APP_STATE: AppState = {
  ...INITIAL_CONFETTI_APP_STATE,
  ...INITIAL_ANALAYTICS_APP_STATE,
  session: { loaded: false },
  userSetPasswordResult: { loaded: false },
  syndicationMatch: { loaded: false },

  connectors: { loaded: false },
  branding: { loaded: false },
  whitelabeling: { loaded: false },
  charts: { loaded: false },
  currentCompanyUser: { loaded: false },
  currentCompanyInvitations: { loaded: false },
  currentCompanyUsers: { loaded: false },
  currentCompany: { loaded: false },
  currentCompanySettings: { loaded: false },
  currentCompanyDataAssets: { loaded: false },
  companies: { loaded: false },
  companyListFilters: [],
  currentCompanyFiles: { loaded: false },
  envelope: { loaded: false },
  docusignConnector: { loaded: false },
  teaserData: { loaded: false },
  investorCompanies: [],
  investorAvailableCompanies: [],
  companyInvestorList: { loaded: false },
  syndicationRecommendations: { loaded: false },
  syndicationMatches: { loaded: false },
  syndicationMatchesForGuest: { loaded: false },
  syndicationPreIntroCharts: { loaded: false },
  syndicationInsightsCharts: { loaded: false },
  syndicationOnboardingMatches: { loaded: false },
  syndicationOnboardingResults: { loaded: false },
  syndicationExistingProofFiles: { loaded: false },
  syndicationMatchTocVersion: { loaded: false },
  invitationRedemption: { loaded: false },
  companyDetailsForInvestorInterest: { loaded: false },
  syndicationConditionsNeedingAcceptance: { loaded: false },
  syndicationMatchOutOfFeeFiles: { loaded: false },
  syndicationMatchDataRoom: { loaded: false },
  syndicationMatchOutReasons: { loaded: false },
  matchResponse: { loaded: false },
  syndicationMatchEvents: {},
  availableConnectors: { loaded: false },

  principalInvestments: { loaded: false },
  principalInvestmentAvailableBalanceSheetPartners: { loaded: false },

  currentMatchProfile: { loaded: false },
  currentMatchProfileAnalytics: { loaded: false },

  investorPortfolioInvites: { loaded: false },
  companyPortfolioInvites: { loaded: false },
  portfolioCompanyInviteUrl: { loaded: false },
  showPortfolioOnboarding: { loaded: false },

  companySignUp: { loaded: false },
  companySignUpAllowedEmail: { loaded: false },
  canCompanyBeAddedToInvestorSubdomainsInvestigation: { loaded: false },
  companyNotices: { loaded: false },
  companyActivationNotices: { loaded: false },
  tosByToken: { loaded: false },

  companyAnalyses: { loaded: false },
  companyAnalysisWarnings: { loaded: false },
  companyPublicProfile: { loaded: false },
  portfolioMonitoringDashboard: { loaded: false },
  portfolioCompanies: { loaded: false },
  portfolioMonitoringCompaniesConnectorStatus: { loaded: false },

  syndicationPermissions: { loaded: false },
  adminSyndicationPermissions: { loaded: false },

  syndicationInvestorDetails: { loaded: false },
  syndicationTransactionDocumentUpload: { loaded: false },
  onboardingv4: ONBOARDING_V4_INITIAL_STATE,
  companySignUpV5: { loaded: false },
  getStartedDismissable: false,
  getStartedDismissed: false,
  isDownloadingCompanyFinancialsFile: { docType: undefined, status: false },
  // admin tabs
  companyUsers: { loaded: false },

  // components
  analyticsCharts: { loaded: false },

  manageInsightsSettings: INITIAL_MANAGE_INSIGHTS_SETTINGS_STATE,
  showCompanyFinancialSummary: false,
  showQuickBooksRawData: false,
  creditBoxReport: { loaded: false },
  isDownloadingCreditBoxReport: false,
};

export enum DocusignMNDAStatus {
  WaitingForSignature,
  WaitingForFile,
  AcceptedMarkdownV1,
  AcceptedMarkdownV2,
}

export enum NoticeName {
  OnboardingTour = 'COMPANY_ONBOARDING_TOUR_COMPLETED', // used for showing a tour on company activation
  InvestorPreferencesVisited = 'INVESTOR_PREFERENCES_VISITED', // used to mark set up your investor preferences as completed on company activation
  PorfolioInvestorGetStarted = 'PORFOLIO_INVESTOR_GET_STARTED', // used for showing a splash screen for portfolio investor
  CompanyActivationClickedLetsGo = 'COMPANY_ACTIVATION_CLICKED_LETS_GO', // only show let's go button once
  CompanyActivationDismissedBanner = 'COMPANY_ACTIVATION_DISMISSED_BANNER', // hide Your funding profile is ready banner on click
  CompanyActivationDataRoomCompleted = 'COMPANY_ACTIVATION_DATA_ROOM_COMPLETED', // only show let's go button once
  CompanyActivationFundingProfileVisited = 'COMPANY_ACTIVATION_FUNDING_PROFILE_VISITED', // mark as complete on click
  CompanyActivationFundingOpportunitiesVisited = 'COMPANY_ACTIVATION_FUNDING_OPPORTUNITIES_VISITED', // mark as complete on click
  CompanyActivationDataRoomDismissed = 'COMPANY_ACTIVATION_DISMISSED_DATAROOM', // hide item card when complete and on click
  CompanyActivationConnectorsDismissed = 'COMPANY_ACTIVATION_DISMISSED_CONNECTORS', // hide item card when complete and on click
  CompanyActivationInvestorPreferencesDismissed = 'COMPANY_ACTIVATION_DISMISSED_INVESTOR_PREFERENCES', // hide item card when complete and on click
  CompanyActivationFundingProfileDismissed = 'COMPANY_ACTIVATION_DISMISSED_FUNDING_PROFILE', // hide item card when complete and on click
  CompanyActivationIntroductionRequestDismissed = 'COMPANY_ACTIVATION_DISMISSED_INTRODUCTION_REQUEST', // hide item card when complete and on click
  CompanyActivationDismissedGetStarted = 'COMPANY_ACTIVATION_DISMISSED_GET_STARTED', // hide menu item
  SyndicationInvestorSetPassword = 'SYNDICATION_INVESTOR_SET_PASSWORD',
  ChooseYourAdventureAccountCreated = 'CHOOSE_YOUR_ADVENTURE_ACCOUNT_CREATED', // choose your adventure onboardingl, show "Create acccount" toast
  ChooseYourAdventureOnboardingCompleted = 'CHOOSE_YOUR_ADVENTURE_ONBOARDING_COMPLETED', // choose your adventure onboardingl, show "Create acccount" toast
  CompanyActivationFundingOpportunitiesDismissed = 'COMPANY_ACTIVATION_DISMISSED_FUNDING_OPPORTUNITIES', // hide item card when complete and on click
}

export type OnboardingV3Payload = {
  // account type
  accountTypeSyndication: boolean;
  accountTypeAnalytics: boolean;
  // company details
  revenueModel: CompanyRevenueModel | string;
  industryVertical: CompanyBusinessType | string;
  state: string;
  numberOfEmployees: string;
  totalEquityRaised: string;
  yearlyRevenue: string;
  yearOverYearGrowthRate: string;
  // financial details
  margins: string;
  ebitdaBurn: string;
  existingDebt: string;
  leverageableAssets: LeverageableAssets | string;
  targetDebtRaise: string;
  fundraisingTimeFrame: string;
  latestPostMoneyValuation: number;
  useOfFunds: string;

  // insights
  [RequiredInsights.RevenueBreakdownAndProjections]: boolean;
  [RequiredInsights.CustomerCohortAcquisitionCostAndLtv]: boolean;
  [RequiredInsights.MarginProgression]: boolean;
  [RequiredInsights.SalesAndMarketingSpendEfficiency]: boolean;
  [RequiredInsights.ScenarioBuilding]: boolean;
  [RequiredInsights.PublicCompanyAndPrivatePeerComparables]: boolean;
  otherRequiredInsights: SelectOption[];

  // Privacy
  requireMutualNda: boolean;
  unwantedInvestors: SelectOption[];
};

export const getUserCompanyId = (state: AppState) => {
  return state.history?.query.company_id || state.session.data?.company?.id;
};
export const getLoginRedirectPath = (redirectPath: string) =>
  `/login?returnTo=${encodeURIComponent(redirectPath)}`;

export const getSyndicationMatchEventResult = (
  record: Record<string, Result<SyndicationMatchEvent>>,
  matchId: number
) => record[matchId];

export const getSession = (state: AppState) => state.session;
