import { getAPI, getHistory, route, takeRequestTrigger } from './utils';
import { request } from '@hum/common/src/ducks/sagas/util';
import { Company, OnboardingStatus } from '@hum/types';
import { call, fork, select, takeEvery } from 'redux-saga/effects';
import { AppState, Routes } from '../state';
import { Result } from '@hum/common/src/ducks/state';
import {
  OnboardingWhetherCompanyCanBeAddedToSubdomainHookUsed,
  apiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged,
  OnboardingActionType,
  OnboardingAddCompanyToInvestorSubdomainHookUsed,
} from '../actions';
import { format, parse } from 'url';
import { API } from '../backend';
import { History } from 'history';

export function* onboardingSaga() {
  // only front-end.
  if (typeof window === 'undefined') {
    return;
  }
  yield fork(handleMNDARedirect);
  yield fork(handleWhetherCompanyCanBeAddedToSubdomain);
}
const isOnboardingCompleted = (company: Result<Company>) => {
  const onboardingStatus = company?.data?.application?.onboardingStatus!;
  return [OnboardingStatus.Complete, OnboardingStatus.LegacyComplete].includes(
    onboardingStatus
  );
};
function* handleMNDARedirect() {
  const history: History = yield getHistory();
  // TEMPORARY REMOVE ME after redirect is added to docusign
  yield takeEvery(
    route(Routes.ONBOARDING_INDEX),
    yield function* () {
      const state: AppState = yield select();

      const isComingFromDocusign =
        state.history?.query.event === 'signing_complete';

      const { ...otherParams } = parse(window.location.href, true).query;
      const urlQuery = format({ query: otherParams });

      let redirectPath = '';
      if (isComingFromDocusign) {
        if (!isOnboardingCompleted(state.currentCompany)) {
          redirectPath = `${Routes.GATEWAY}${urlQuery}`;
        } else {
          return;
        }
      } else {
        return;
      }

      setTimeout(() => {
        history?.push(redirectPath);
      }, 100);
    }
  );
}

function* handleWhetherCompanyCanBeAddedToSubdomain() {
  yield takeRequestTrigger(
    [
      OnboardingActionType.ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_HOOK_USED,
    ],
    function* ({
      payload: { companyId, investorSubdomain },
    }: OnboardingWhetherCompanyCanBeAddedToSubdomainHookUsed) {
      yield call(
        investigateIfCompanyCanBeAddedToInvestorSubdomain,
        companyId,
        investorSubdomain
      );
    }
  );

  function* investigateIfCompanyCanBeAddedToInvestorSubdomain(
    companyId: number,
    investorSubdomain: string
  ) {
    const api: API = yield getAPI();
    yield request(
      apiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged,
      () =>
        api.onboarding.canCompanyBeAddedToInvestorSubdomain(
          companyId,
          investorSubdomain
        )
    );
  }

  yield takeRequestTrigger(
    [
      OnboardingActionType.ONBOARDING_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_HOOK_USED,
    ],
    function* ({
      payload: { companyId, investorSubdomain },
    }: OnboardingAddCompanyToInvestorSubdomainHookUsed) {
      yield call(addCompanyToInvestorSubdomains, companyId, investorSubdomain);
    }
  );

  function* addCompanyToInvestorSubdomains(
    companyId: number,
    investorSubdomain: string
  ) {
    const api: API = yield getAPI();
    yield request(
      apiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged,
      () =>
        api.onboarding.addCompanyToInvestorSubdomains(
          companyId,
          investorSubdomain
        )
    );
  }
}
