import confetti from 'canvas-confetti';

export default function () {
  confetti({
    angle: 90,
    particleCount: 150,
    startVelocity: 40,
    ticks: 500,
    gravity: 0.9,
    spread: 100,
    zIndex: 9999,
    origin: { y: 0.5, x: 0.5 },
  } as confetti.Options);
}
