/**
 * Checks if the operating system is Windows.
 * This function inspects the user agent string to determine if the OS is Windows.
 * It looks for multiple indicators such as 'Windows NT' for modern Windows versions
 * and 'Win' for broader matches, including older versions and any variations.
 *
 * @returns {boolean} - Returns true if the OS is Windows, false otherwise.
 */
export const isWindowsOS = () => {
  const userAgent = window.navigator.userAgent;
  return /Windows NT|Win/.test(userAgent);
};
