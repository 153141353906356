import {
  trackAnalayticsEvent,
  trackActions,
} from '@hum/icm-app/src/modules/analytics';
import { takeEvery } from 'redux-saga/effects';
import { ActionType, ExternalServiceConnected } from '../actions';
import { SyndicationActionType } from '@hum/icm-app/src/actions';

/**
 * Saga for handling _user facing_ side effects
 */

export function* trackGeneralAppEvents() {
  yield takeEvery(
    ActionType.EXTERNAL_SERVICE_CONNECTED,
    trackExternalServiceConnected
  );

  yield trackActions([
    {
      actionType: SyndicationActionType.SYNDICATION_CONTACT_SALES_CLICKED,
      track: 'syndication:contact:sales:clicked',
    },
  ]);
}

function* trackExternalServiceConnected({
  payload: { companyId, connectorType },
}: ExternalServiceConnected) {
  yield trackAnalayticsEvent(`linked-accounts:connect`, {
    companyID: companyId,
    connectorType,
  });
  // new tracking language
  yield trackAnalayticsEvent(`company-connect-service`, {
    companyID: companyId,
    connectorType,
  });
}
