import { call, put, fork, takeEvery } from 'redux-saga/effects';
import {
  CompanyDashboardType,
  CompanyDashboardDisconnectServiceButtonClicked,
  companyDashboardDisconnectServiceButtonClickedSuccess,
  companyDashboardDisconnectServiceButtonClickedFail,
  CompanyDashboardServiceStartDataPullButtonClicked,
  companyDashboardServiceStartDataPullButtonClickedSuccess,
  companyDashboardServiceStartDataPullButtonClickedFail,
} from '@hum/icm-app/src/actions/companyDashboard';
import { API } from '@hum/icm-app/src/backend';
import { getAPI } from './utils';
import { toast } from '@hum/common/src/modules/toast';

export function* handleCompanyDashboard() {
  yield fork(handleService);
}

function* handleService() {
  yield takeEvery(
    CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED,
    handleDisconnectServiceButtonClicked
  );
  yield takeEvery(
    CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED,
    handleServiceStartDataPullButtonClicked
  );
}

function* handleDisconnectServiceButtonClicked({
  payload,
}: CompanyDashboardDisconnectServiceButtonClicked) {
  const api: API = yield getAPI();
  try {
    const isAirbyteService = Boolean(payload.isAirbyteService);
    const isCodatService = Boolean(payload.isCodatService);
    if (isAirbyteService) {
      yield call(api.companyDashboard.disconnectAirbyteService, payload);
    } else if (isCodatService) {
      yield call(api.companyDashboard.disconnectCodatService, payload);
    } else {
      yield call(api.companyDashboard.disconnectService, payload);
    }
    yield call(() => toast.info('Service disconnected successfully'));
    yield put(companyDashboardDisconnectServiceButtonClickedSuccess());
  } catch (error) {
    yield call(() => toast.error('Failed to disconnect service'));
    yield put(companyDashboardDisconnectServiceButtonClickedFail());
    throw error;
  }
}

function* handleServiceStartDataPullButtonClicked({
  payload,
}: CompanyDashboardServiceStartDataPullButtonClicked) {
  const api: API = yield getAPI();

  try {
    const isAirbyteService = Boolean(payload.isAirbyteService);
    const startServiceDataPullCall = isAirbyteService
      ? api.companyDashboard.serviceStartDataPullAirbyte
      : api.companyDashboard.serviceStartDataPull;
    yield call(startServiceDataPullCall, payload);
    yield call(() => toast.info('Data pull started!'));
    yield put(companyDashboardServiceStartDataPullButtonClickedSuccess());
  } catch (error) {
    yield call(() => toast.error('Failed to start data pull'));
    yield put(companyDashboardServiceStartDataPullButtonClickedFail());
  }
}
