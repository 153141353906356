import { parse } from 'querystring';
import { useRouter as useRouter2 } from '@hum/icm-app/src/hooks/useRouter';

// eslint-disable-next-line no-duplicate-imports
export * from 'use-query-params';

/*
 * Extract the query params as object from a url or pathname.
 */
export const getQuery = (path: string) => {
  const question = path.includes('?') ? path.indexOf('?') + 1 : path.length;
  const hash =
    (path.includes('#') ? path.indexOf('#') : path.length) - question;
  const query = path.substr(question, hash);

  return parse(query);
};

/**
 * Augmented useRouter version to make available any query params.
 * @see: https://github.com/zeit/next.js/issues/2910
 * @see: https://github.com/zeit/next.js/issues/4804
 */
const useRouter = () => {
  const router = useRouter2();

  router.query = Object.assign(getQuery(router.asPath), router.query);

  return router;
};

export { useRouter };
