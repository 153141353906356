import { parse, format } from 'url';
import {
  loadSession,
  route,
  takeRequestTrigger,
} from '@hum/icm-app/src/sagas/utils';
import {
  AuthActionType,
  ApiUserLogoutRequestStateChanged,
  SignupIsInvitationRedeemedHookUsed,
  apiSignupIsInvitationRedeemedStateChanged,
  AllowedEmailRequestSent,
  allowedEmailRequestStateChanged,
  apiRequestFailed,
  apiUserLoginRequestStateChanged,
  companyRequestStateChanged,
  apiUserVerifyEmailequestStateChanged,
  apiUserVerifyEmailequestStateSucceeded,
  resendVerificationEmailButtonClickedSuccess,
  resendVerificationEmailButtonClickedFail,
} from '../actions';
import { request } from '@hum/common/src/ducks/sagas/util';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { CompanyType, User } from '@hum/types';
import { AppState, Routes } from '../state';
import { getSubdomain } from '@hum/icm-app/src/utils';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';
import { EmailAddressAcceptability } from '@hum/icm-app/src/state';
import { API } from '../backend';
import { getAPI, getHistory } from './utils/api';
import { History } from 'history';
import { toast } from '@hum/common/src/modules/toast';

export function* authSaga() {
  yield fork(handleUserSession);
  yield fork(handleOauth);
  yield fork(handleRoot);
}

function* handleUserSession() {
  const history: History = yield getHistory();
  yield takeRequestTrigger(
    [
      AuthActionType.USER_LOGIN_REQUEST_STATE_CHANGED,
      AuthActionType.USER_SET_PASSWORD_REQUEST_STATE_CHANGED,
      AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED,
    ],
    function* () {
      const state: AppState = yield select();
      if (
        state.session.loaded &&
        typeof state.session.data?.id !== 'undefined'
      ) {
        const user = state.session.data;
        const redirectUrl = getAuthRedirectUrl(user, window.location.href);
        if (redirectUrl) {
          redirect(redirectUrl, history);
        }
      }
    }
  );
  yield takeRequestTrigger(
    [AuthActionType.USER_LOGOUT_REQUEST_STATE_CHANGED],
    function* (action: ApiUserLogoutRequestStateChanged) {
      const params = parse(window && window.location.href, true).query;
      const urlQuery = format({ query: params });
      if (action.payload.result.data?.success) {
        history?.push(`${Routes.LOGIN}${urlQuery}`);
      }
    }
  ),
    yield takeRequestTrigger(
      [AuthActionType.USER_REVIEW_SESSION_REQUEST_SENT],
      function* () {
        yield call(userReviewSessionRequestSent);
      }
    );

  yield takeRequestTrigger(
    [AuthActionType.USER_REVIEW_SESSION_REQUEST_STATE_CHANGED],
    function* () {
      const { session }: AppState = yield select();
      if (
        session.loaded &&
        session.data?.id &&
        (window.location.href.includes(Routes.LOGIN) ||
          window.location.href.includes(Routes.SIGNUP) ||
          window.location.href.includes(Routes.RAISE_OR_INVEST) ||
          window.location.pathname === Routes.ROOT)
      ) {
        const redirectUrl = getAuthRedirectUrl(
          session.data,
          window.location.href
        );

        if (redirectUrl) {
          redirect(redirectUrl, history);
        }
      }
    }
  );

  yield takeRequestTrigger(
    [AuthActionType.SIGNUP_IS_INVITATION_REDEEMED_HOOK_USED],
    function* ({
      payload: { invitationHash },
    }: SignupIsInvitationRedeemedHookUsed) {
      yield call(loadSignupIsInvitationRedeemed, invitationHash);
    }
  );

  yield takeRequestTrigger(
    [AuthActionType.ALLOWED_EMAIL_REQUEST_SENT],
    function* ({ payload: { email } }: AllowedEmailRequestSent) {
      yield call(reviewIfEmailIsAllowed, email);
    }
  );

  yield takeEvery(
    AuthActionType.SIGNUP_V5_REQUEST_SENT,
    catchError(function* ({ payload }) {
      yield call(companySignUpV5, payload);
      history.push(Routes.ABOUT_YOUR_BUSINESS, {
        signUpEmail: payload.workEmail,
      });
    }, noop)
  );
  yield takeEvery(
    AuthActionType.USER_VERIFY_EMAIL_VISITED,
    catchError(function* ({ payload }) {
      yield call(userVerifyEmail, payload);
    }, noop)
  );
  yield takeEvery(
    AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED,
    catchError(function* () {
      yield call(userResendVerifyEmail);
    }, noop)
  );
}

function* companySignUpV5(payload) {
  const api: API = yield getAPI();
  try {
    const { session, company_id: companyId } = yield call(
      apiCompanySignUpV5,
      payload,
      api
    );
    yield request(companyRequestStateChanged, () => {
      return api.legacy.getCompany(companyId);
    });
    yield request(apiUserLoginRequestStateChanged, () =>
      api.auth.normalizeSession(session.user)
    );
  } catch (e: any) {
    yield put(
      apiRequestFailed({
        error: e,
        reason: e?.response?.data
          ? mapSignUpError(e?.response?.data)
          : e.message,
      })
    );
    yield call(() => toast.error(e.message));
    throw e;
  }
}

function* userVerifyEmail(payload) {
  const api: API = yield getAPI();
  try {
    const data = yield call(apiUserVerifyEmail, payload, api);
    yield put(apiUserVerifyEmailequestStateSucceeded());
    yield request(apiUserVerifyEmailequestStateChanged, () =>
      api.auth.normalizeSession(data.user)
    );
  } catch (e: any) {
    yield put(
      apiRequestFailed({ error: e, reason: mapSignUpError(e.response.data) })
    );
    throw e;
  }
}

function* userResendVerifyEmail() {
  const api: API = yield getAPI();
  try {
    yield call(apiResendVerificationEmail, api);
    yield call(() => toast.success('Email sent!'));
    yield put(resendVerificationEmailButtonClickedSuccess());
  } catch (e: any) {
    yield put(
      apiRequestFailed({ error: e, reason: mapSignUpError(e.response.data) })
    );
    yield call(() =>
      toast.error('There was an error re-sending the verification email')
    );
    yield put(resendVerificationEmailButtonClickedFail());
    throw e;
  }
}

export const getAuthRedirectUrl = (user: User, href: string) => {
  // when logging in, recovering account, keep query string around but remove
  // company, name, and action (used at recover account email)
  // when targeting a company by external urls please use company_id instead
  const {
    token: _token,
    name: _name,
    company: _company,
    action: _action,
    returnTo,
    ...otherParams
  } = parse(href, true).query;

  const urlQuery = format({ query: otherParams });
  const { isLocalhost, subdomain } = getSubdomain();

  let redirectPathname;
  if (!!returnTo?.length) {
    redirectPathname = `${returnTo}${urlQuery}`;
  } else {
    if (user.roles.includes(CompanyType.Admin)) {
      redirectPathname = `${Routes.ADMIN}${urlQuery}`;
    } else if (user.roles.includes(CompanyType.Company)) {
      redirectPathname = `${Routes.GATEWAY}${urlQuery}`;
    } else if (user.roles.includes(CompanyType.Investor)) {
      redirectPathname = `${Routes.PORTFOLIO_MONITORING}${urlQuery}`;
    } else if (user.roles.includes(CompanyType.BalanceSheetPartner)) {
      // determinate if should redirect to admin
      redirectPathname = `${Routes.PORTFOLIO_COMPANIES}${urlQuery}`;
    } else if (user.roles.includes(CompanyType.SyndicationInvestor)) {
      redirectPathname = `${Routes.SYNDICATION}${urlQuery}`;
    }
  }

  if (redirectPathname) {
    let { pathname, query }: any = parse(redirectPathname, true);
    let { host, protocol } = window.location;

    if (isLocalhost) {
      // add subdomain as query string param if is local env
      // DO NOT MERGE THESE 2 IFs (they are not the same condition)
      if (subdomain) {
        query.subdomain = subdomain;
      } else if (user.subdomain) {
        query.subdomain = user.subdomain;
      }
    } else {
      // if not local and not user subdomain go to tcm
      host = user.preferredHost;
    }

    if (process.env.REDIRECT_TO_TEST === 'true') {
      const redirectUrl = format({
        protocol: 'http',
        host: 'icm.humcapital.test:3000',
        pathname,
        query,
      });

      return redirectUrl;
    }

    const redirectUrl = format({
      protocol,
      host,
      pathname,
      query,
    });

    return redirectUrl;
  }
};

function* userReviewSessionRequestSent(): any {
  return yield call(loadSession);
}

function* loadSignupIsInvitationRedeemed(invitationHash: string) {
  const api: API = yield getAPI();
  yield request(apiSignupIsInvitationRedeemedStateChanged, () =>
    api.auth.getIsInvitationRedeemed(invitationHash)
  );
}

function* handleOauth() {}

function* handleRoot() {
  yield takeRequestTrigger(
    [
      route(Routes.ROOT),
      route(Routes.LOGIN),
      route(Routes.SIGNUP),
      route(Routes.RAISE_OR_INVEST),
    ],
    loadSession
  );
}

const redirect = (url: string, history: History) => {
  const parts = parse(url, true);
  if (parts.host !== window.location.host) {
    window.location.assign(url);
  } else {
    history?.push(parts.pathname! + parts.search || '');
  }
};

function* reviewIfEmailIsAllowed(email: string) {
  const api: API = yield getAPI();
  try {
    yield request(allowedEmailRequestStateChanged, () =>
      api.auth.reviewIfEmailIsAllowed(email)
    );
  } catch (error: any) {
    yield put(
      apiRequestFailed({ error, reason: 'Failed to get email acceptability' })
    );
    throw error;
  }
}

const apiCompanySignUpV5 = async (payload, api: API) => {
  try {
    return await api.auth.postSignupV5(
      payload.workEmail,
      payload.firstName,
      payload.lastName,
      payload.companyRole,
      payload.companyName,
      payload.website,
      payload.password,
      payload.acceptedCommunications,
      payload.acceptedPrivacyPolicy,
      payload.acceptedTos,
      payload.application,
      payload.invitationHash,
      payload.channel,
      payload.utmMedium,
      payload.utmSource,
      payload.utmCampaign,
      payload.utmTerm,
      payload.utmContent,
      payload.subdomain
    );
  } catch (error) {
    throw error;
  }
};

const apiUserVerifyEmail = async (payload, api: API) => {
  try {
    return await api.auth.verifyEmail(payload);
  } catch (error) {
    throw error;
  }
};

const apiResendVerificationEmail = async (api: API) => {
  try {
    return await api.auth.resendVerifyEmail();
  } catch (error) {
    throw error;
  }
};

const mapSignUpError = (data) => {
  if (!data) {
    return 'There was an error';
  } else if (data === EmailAddressAcceptability.alreadyExists) {
    return 'Email already exists';
  } else if (data === EmailAddressAcceptability.isBlackListed) {
    return 'Please use a valid work email address';
  } else {
    return 'There was an error validating email account';
  }
};
