// How this works:
// This Google Ads page has all the conversions we want to track
// https://ads.google.com/aw/conversions?ocid=615177317
// Please note: if/when asked which Google Ads account you want to use, use 446-202-4460
import { getSubdomain } from '@hum/icm-app/src/utils';

// Last updated 2021-08-05
// Current events we're tracking
export enum GoogleAdsConversions {
  companySignupSubmit = 'AW-459218021/g7_0CO-76IkCEOW4_NoB', // https://ads.google.com/aw/conversions/detail?ocid=615177317&ctId=557456879
  companySignupPageview = 'AW-459218021/SpPyCPDR6IkCEOW4_NoB', // https://ads.google.com/aw/conversions/detail?ocid=615177317&ctId=557459696
  marketingSiteCtaClicked = 'AW-459218021/wf1NCKii6YkCEOW4_NoB', // https://ads.google.com/aw/conversions/detail?ocid=615177317&ctId=557469992
  companyProfileComplete = 'AW-459218021/dQbWCOOsjYoCEOW4_NoB', // https://ads.google.com/aw/conversions/detail?ocid=615177317&ctId=558061155
  investorSignupSubmit = 'AW-459218021/yISNCPzZkIoCEOW4_NoB', // https://ads.google.com/aw/conversions/detail?ocid=615177317&ctId=558116092
}

export const trackGoogleAdsConversion = (
  sendTo: GoogleAdsConversions,
  callback?: () => void
) => {
  const gtag = window['gtag'];
  const { isPrimarySubdomain, isDemo } = getSubdomain();
  if (gtag && isPrimarySubdomain && !isDemo) {
    gtag('event', 'conversion', {
      send_to: sendTo,
      event_callback: callback,
    });
  }
};
