import { call, fork } from 'redux-saga/effects';
import { getAPI, takeRequestTrigger } from './utils';
import { request, BaseCompanyAction } from '@hum/common';
import {
  apiAvailableBalanceSheetPartnersStateChanged,
  apiPrincipalInvestmentsStateChanged,
  PrincipalInvestmentActionType,
  PrincipalInvestmentsHookUsed,
} from '../actions';
import { API } from '../backend';

export function* principalInvestmentSaga() {
  yield fork(handleCompanyPrincipalInvestment);
}

function* handleCompanyPrincipalInvestment() {
  yield takeRequestTrigger(
    [
      PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_HOOK_USED,
      PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_CREATED,
      PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_STATE_CHANGED,
    ],
    function* ({ payload: { companyId } }: PrincipalInvestmentsHookUsed) {
      yield call(loadPrincipalInvestments, companyId);
    }
  );
  yield takeRequestTrigger(
    [
      PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_AVAILABLE_BALANCE_SHEET_PARTNER_HOOK_USED,
    ],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      yield call(loadAvailableBalanceSheetPartner, companyId);
    }
  );
}

function* loadPrincipalInvestments(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiPrincipalInvestmentsStateChanged, () =>
    api.principalInvestment.getPrincipalInvestments(companyId)
  );
}

function* loadAvailableBalanceSheetPartner(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiAvailableBalanceSheetPartnersStateChanged, () =>
    api.principalInvestment.getAvailableBalanceSheetPartner(companyId)
  );
}
