import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  getAPI,
  getHistory,
  loadCurrentCompany,
  takeRequestTrigger,
} from './utils';
import {
  AdminActionType,
  AdminAddCompanyFormSubmitted,
  adminAddCompanyFormSubmittedFail,
  adminAddCompanyFormSubmittedSuccess,
  AdminAddMndaFileSubmitted,
  adminAddMndaFileSubmittedFail,
  adminAddMndaFileSubmittedSuccess,
  adminCompanyUsersFailed,
  AdminCompanyUsersHookUsed,
  adminCompanyUsersStateChanged,
  AdminInviteUserFormSubmitted,
  adminInviteUserFormSubmittedFailed,
  adminInviteUserFormSubmittedSuccess,
  AdminUpdateUserFormSubmitted,
  adminUpdateUserFormSubmittedFailed,
  adminUpdateUserFormSubmittedSuccess,
  adminCompanyUsersHookUsed,
} from '../actions';
import { AppState } from '../state';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { format } from 'url';
import { request } from '@hum/common/src/ducks/sagas/util';
import { analytics } from '@hum/common';
import { API } from '@hum/icm-app/src/backend';
import { History } from 'history';
import {
  AdminUpdateUser,
  AdminCreateUser,
} from '@hum/icm-app/src/backend/api/admin';
import { toast } from '@hum/common/src/modules/toast';

export function* adminSaga() {
  yield fork(handleCompanyUsers);
  yield fork(handleUpdateUser);
  yield fork(handleInviteUser);
  yield fork(handleCreateCompanyV5);
  yield fork(handleUploadMnda);
}

function* handleCompanyUsers() {
  yield takeRequestTrigger(
    [AdminActionType.ADMIN_COMPANY_USERS_HOOK_USED],
    function* ({ payload: { companyId } }: AdminCompanyUsersHookUsed) {
      try {
        yield call(loadCompanyUsers, companyId);
      } catch (error: any) {
        yield call(() =>
          toast.error(error.message || 'Failed to get company users')
        );
        yield put(adminCompanyUsersFailed(error));
        throw error;
      }
    }
  );
}

function* handleInviteUser() {
  yield takeEvery(
    AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED,
    catchError(function* ({ payload }: AdminInviteUserFormSubmitted) {
      yield call(saveUser, payload);
    }, noop)
  );
}
function* handleUpdateUser() {
  yield takeEvery(
    AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED,
    catchError(function* ({ payload }: AdminUpdateUserFormSubmitted) {
      yield call(updateUser, payload);
    }, noop)
  );
}
function* handleCreateCompanyV5() {
  yield takeEvery(
    AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED,
    catchError(function* ({ payload }: AdminAddCompanyFormSubmitted) {
      yield call(saveCompanyV5, payload);
    }, noop)
  );
}

function* handleUploadMnda() {
  yield takeEvery(
    AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED,
    catchError(function* ({
      payload: { companyId },
    }: AdminAddMndaFileSubmitted) {
      yield call(uploadMnda, companyId);
    },
    noop)
  );
}

function* loadCompanyUsers(companyId: number) {
  const api: API = yield getAPI();
  yield request(adminCompanyUsersStateChanged, () =>
    api.legacy.getCompanyUsers(companyId)
  );
  const state: AppState = yield select();
  const error = state.companyUsers.error;
  if (error) {
    throw error;
  }
}

function* saveUser({
  payload,
  companyId,
}: {
  payload: AdminCreateUser;
  companyId: number;
}) {
  const api: API = yield getAPI();
  try {
    yield call(api.admin.createUser, { ...payload, companyId });
    yield call(() => toast.success('User invited succesfully!'));
    yield put(adminInviteUserFormSubmittedSuccess());
    yield put(adminCompanyUsersHookUsed({ companyId }));
  } catch (error: any) {
    yield call(() => toast.error(error.message || 'Failed to invite user'));
    yield put(adminInviteUserFormSubmittedFailed());
    throw error;
  }
}

function* updateUser({
  payload,
  companyId,
}: {
  payload: AdminUpdateUser;
  companyId: number;
}) {
  const api: API = yield getAPI();
  try {
    yield call(api.admin.updateUser, payload);
    yield call(() => toast.success('User updated succesfully!'));
    yield put(adminUpdateUserFormSubmittedSuccess());
    yield put(
      adminCompanyUsersHookUsed({
        companyId,
      })
    );
  } catch (error: any) {
    yield call(() => toast.error(error.message || 'Failed to update user'));
    yield put(adminUpdateUserFormSubmittedFailed(error));
    throw error;
  }
}

function* saveCompanyV5(payload) {
  const api: API = yield getAPI();
  const history: History = yield getHistory();
  try {
    const data = yield call(api.legacy.adminCreateCompanyV5, payload);
    // @ts-ignore
    analytics.track('admin:company_create:success');
    const company = normalize.company.in(data.company);

    history.push(
      format({
        pathname: '/admin',
        query: { company_id: company.id },
      })
    );

    yield call(() => toast.success('Company created succesfully!'));
    yield put(adminAddCompanyFormSubmittedSuccess());
  } catch (error: any) {
    yield call(() => toast.error(error.message || 'Failed to create company'));
    yield put(adminAddCompanyFormSubmittedFail(error));
    // @ts-ignore
    analytics.track('admin:company_create:fail');
    throw error;
  }
}

function* uploadMnda(companyId) {
  const api: API = yield getAPI();
  try {
    yield call(api.legacy.adminUploadMnda, companyId);
    yield call(loadCurrentCompany);
    yield call(() => toast.success('MNDA signed!'));
    yield put(adminAddMndaFileSubmittedSuccess());
  } catch (error: any) {
    yield call(() => toast.error(error.message || 'Failed to sign MNDA'));
    yield put(adminAddMndaFileSubmittedFail(error));
    throw error;
  }
}
