import { call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { getHistory, takeRequestTrigger } from './utils';
import { apiSendDealToArchive, apiCompanyIsFundraising } from './utils/model';
import {
  apiSyndicationMatchesForGuestStateChanged,
  apiSyndicationMatchesStateChanged,
  apiSyndicationMatchEventsStateChanged,
  apiSyndicationMatchProfileStateChanged,
  apiSyndicationOnboardingMatchesStateChanged,
  apiSyndicationOnboardingResultsStateChanged,
  apiSyndicationRecommendationsStateChanged,
  SyndicationActionType,
  SyndicationMatchesHookUsed,
  SyndicationMatchEventsHookUsed,
  SyndicationMatchProfileHookUsed,
  SyndicationMatchProfileAnalyticsHookUsed,
  apiSyndicationMatchOutReasonsStateChanged,
  SyndicationOnboardingMatchesForGuestHookUsed,
  SyndicationOnboardingMatchesHookUsed,
  SyndicationOnboardingResultsHookUsed,
  SyndicationRecommendationsHookUsed,
  SyndicationExistingProofFilesHookUsed,
  SyndicationInvestorShowsInterestInCompanyHookUsed,
  apiSyndicationMatchProfileAnalyticsStateChanged,
  apiSyndicationExistingProofFilesStateChanged,
  apiSyndicationInvestorShowsInterestInCompanyStateChanged,
  SyndicationSendMatchResponseHookUsed,
  apiSyndicationSendMatchResponseStateChanged,
  SyndicationSendSawCardHookUsed,
  SyndicationAssociateFileToOutOfFeeProofsHookUsed,
  SyndicationConditionsNeedingAcceptanceHookUsed,
  apiSyndicationConditionsNeedingAcceptanceStateChanged,
  SyndicationInvestorAcceptsLegalConditionsHookUsed,
  apiSyndicationLoadOutOfFeeFilesStateChanged,
  SyndicationMatchDataroomHookUsed,
  PreIntroChartsHookUsed,
  apiPreIntroChartsStateChanged,
  apiSyndicationInsightsChartsStateChanged,
  apiSyndicationMatchRequestStateChanged,
  SyndicationMatchHookUsed,
  SyndicationMatchesV2HookUsed,
  SyndicationInsightsChartsHookUsed,
  apiSyndicationMatchDataroomStateChanged,
  PermissionsCheckHookUsed,
  permissionsCheckStateChanged,
  permissionsAdminStateChanged,
  AdminClickedSendIntros,
  SyndicationInvestorDetailsHookUsed,
  syndicationInvestorDetailsStateChanged,
  SyndicationInvestorDetailsStateChanged,
  SendTransactionDocumentButtonClicked,
  transactionDocumentUploadResponse,
  transactionDocumentEmailSent,
  ApiSendDealToArchive,
  ApiCompanyIsFundraising,
  apiSyndicationCompanyMatchCreated,
  adminSuccessfullySentIntros,
  adminFailedToSendIntros,
} from '@hum/icm-app/src/actions';
import { request } from '@hum/common/src/ducks/sagas/util';
import { CompanyType } from '@hum/types';
import { SyndicationSectionDeals, Routes } from '@hum/icm-app/src/state';
import {
  createLoadingResult,
  createDataResult,
  createErrorResult,
} from '@hum/common/src/ducks/state';
import { API } from '@hum/icm-app/src/backend/api';
import { getAPI } from './utils';
import { History } from 'history';
import { toast } from '@hum/common/src/modules/toast';

export function* syndicationSaga() {
  yield fork(handleCompanySyndication);
  yield fork(handleSyndicationMatchProfile);
  yield fork(handleUserClickedSendToArchiveButton);
  yield fork(handleNotifyWhenCompanyIsFundraising);
}
function* handleUserClickedSendToArchiveButton() {
  const history: History = yield getHistory();
  yield takeEvery(
    SyndicationActionType.USER_CLICKED_SEND_TO_ARCHIVE_BUTTON,
    function* ({
      payload: { companyId, matchUuid, userType, reasons },
    }: ApiSendDealToArchive) {
      yield call(apiSendDealToArchive, companyId, matchUuid, userType, reasons);
      history.push(Routes.SYNDICATION_NEW_DEALS);
    }
  );
}
function* handleNotifyWhenCompanyIsFundraising() {
  yield takeEvery(
    SyndicationActionType.NOTIFY_USER_COMPANY_IS_FUNDRAISING,
    function* ({
      payload: { companyId, matchUuid, isFundraising },
    }: ApiCompanyIsFundraising) {
      yield call(apiCompanyIsFundraising, companyId, matchUuid, isFundraising);
    }
  );
}
function* handleCompanySyndication() {
  yield takeRequestTrigger(
    [
      SyndicationActionType.SYNDICATION_RECOMMENDATIONS_HOOK_USED,
      SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_CREATED,
      SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_DELETED,
    ],
    function* ({ payload: { companyId } }: SyndicationRecommendationsHookUsed) {
      yield call(loadSyndicationRecommendations, companyId);
    }
  );
  yield takeRequestTrigger(
    [
      SyndicationActionType.SYNDICATION_MATCHES_HOOK_USED,
      SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_CREATED,
      SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_UPDATED,
      SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_DELETED,
    ],
    function* ({ payload: { companyId } }: SyndicationMatchesHookUsed) {
      yield call(loadSyndicationMatches, companyId);
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_INVESTOR_V2_MATCHES_HOOK_USED],
    function* ({
      payload: { companyId, sectionDeals },
    }: SyndicationMatchesV2HookUsed) {
      yield call(loadSyndicationV2Matches, companyId, sectionDeals);
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_HOOK_USED],
    function* ({ payload: { companyId, uuid } }: SyndicationMatchHookUsed) {
      yield call(loadSyndicationMatch, companyId, uuid);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.PRE_INTRO_CHARTS_HOOK_USED],
    function* ({
      payload: { matchUuid, companyAnalysisId },
    }: PreIntroChartsHookUsed) {
      yield call(loadPreIntroCharts, matchUuid, companyAnalysisId);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_INSIGHTS_CHARTS_HOOK_USED],
    function* (action: SyndicationInsightsChartsHookUsed) {
      yield call(
        loadSyndicationInsightsCharts,
        apiSyndicationInsightsChartsStateChanged,
        action.payload.companyId,
        action.payload.companyAnalysisId
      );
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_HOOK_USED],
    function* ({
      payload: { companyId, companyType },
    }: SyndicationOnboardingMatchesHookUsed) {
      yield call(loadSyndicationOnboardingMatches, companyId, companyType);
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_EXISTING_PROOF_FILES_HOOK_USED],
    function* ({
      payload: { companyId, matchUuid },
    }: SyndicationExistingProofFilesHookUsed) {
      yield call(loadSyndicationExistingProofFiles, companyId, matchUuid);
    }
  );
  yield takeRequestTrigger(
    [
      SyndicationActionType.SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_HOOK_USED,
    ],
    function* ({
      payload: { companyId, matchUuid },
    }: SyndicationInvestorShowsInterestInCompanyHookUsed) {
      yield call(
        sendSyndicationInvestorShowsInterestInCompany,
        companyId,
        matchUuid
      );
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_SEND_MATCH_RESPONSE_HOOK_USED],
    function* ({
      payload: { companyId, companyType, payload },
    }: SyndicationSendMatchResponseHookUsed) {
      yield call(
        sendSyndicationSendMatchResponse,
        companyId,
        companyType,
        payload
      );
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_SEND_SAW_CARD_HOOK_USED],
    function* ({
      payload: { companyId, companyType, matchUuid },
    }: SyndicationSendSawCardHookUsed) {
      yield call(sendSyndicationSendSawCard, companyId, companyType, matchUuid);
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_HOOK_USED],
    function* ({
      payload: { companyId },
    }: SyndicationConditionsNeedingAcceptanceHookUsed) {
      yield call(loadSyndicationConditionsNeedingAcceptance, companyId);
    }
  );

  yield takeRequestTrigger(
    [
      SyndicationActionType.SYNDICATION_INVESTOR_ACCEPTS_LEGAL_CONDITIONS_HOOK_USED,
    ],
    function* ({
      payload: { companyId, payload },
    }: SyndicationInvestorAcceptsLegalConditionsHookUsed) {
      yield call(
        sendSyndicationInvestorAcceptsLegalConditions,
        companyId,
        payload
      );
    }
  );

  yield takeRequestTrigger(
    [
      SyndicationActionType.SYNDICATION_ASSOCIATE_FILE_TO_OUT_OF_FEE_PROOFS_HOOK_USED,
    ],
    function* ({
      payload: { companyId, matchUuid, createdFileId },
    }: SyndicationAssociateFileToOutOfFeeProofsHookUsed) {
      yield call(
        sendSyndicationAssociateFileToOutOfFeeProofs,
        companyId,
        matchUuid,
        createdFileId
      );
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_FOR_GUEST_HOOK_USED],
    function* ({
      payload: { token },
    }: SyndicationOnboardingMatchesForGuestHookUsed) {
      yield call(loadSyndicationOnboardingMatchesForGuest, token);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_ONBOARDING_RESULTS_HOOK_USED],
    function* ({
      payload: { companyId, companyType },
    }: SyndicationOnboardingResultsHookUsed) {
      yield call(loadSyndicationOnboardingResults, companyId, companyType);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_OUT_REASONS_HOOK_USED],
    function* () {
      yield call(loadSyndicationMatchOutReasons);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_EVENTS_HOOK_USED],
    function* ({
      payload: { companyId, matchId },
    }: SyndicationMatchEventsHookUsed) {
      yield call(loadSyndicationMatchEvents, companyId, matchId);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_LOAD_OUT_OF_FEE_FILES_HOOK_USED],
    function* ({
      payload: { companyId, matchId },
    }: SyndicationMatchEventsHookUsed) {
      yield call(loadSyndicationLoadOutOfFeeFiles, companyId, matchId);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_DATAROOM_HOOK_USED],
    function* ({ payload: { companyId } }: SyndicationMatchDataroomHookUsed) {
      yield call(loadSyndicationDataRoom, companyId);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.PERMISSIONS_CHECK_HOOK_USED],
    function* ({
      payload: { companyId, investorId, adminMatchPermissions },
    }: PermissionsCheckHookUsed) {
      yield call(
        loadCompanyPermissions,
        companyId,
        adminMatchPermissions,
        investorId
      );
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.ADMIN_CLICKED_SEND_INTROS],
    function* ({
      payload: { companyId, adminMatchPermissions, matchIds },
    }: AdminClickedSendIntros) {
      yield call(sendMatchStatusNotification, companyId, matchIds);
      yield call(loadCompanyPermissions, companyId, adminMatchPermissions);
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED],
    function* ({
      payload: {
        result: { data, loaded },
      },
    }: SyndicationInvestorDetailsStateChanged) {
      if (loaded) {
        yield call(
          loadCompanyPermissions,
          data!.companyId,
          false,
          data!.investorUserId
        );
      }
    }
  );
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_HOOK_USED],
    function* ({
      payload: { companyId, matchUuid },
    }: SyndicationInvestorDetailsHookUsed) {
      yield call(loadSyndicationInvestorDetails, companyId, matchUuid);
    }
  );

  yield takeLeading(
    [SyndicationActionType.SEND_TRANSACTION_DOCUMENT_BUTTON_CLICKED],
    function* ({
      payload: { companyId, matchUuid },
    }: SendTransactionDocumentButtonClicked) {
      yield call(transactionDocumentUploadedEmail, companyId, matchUuid);
    }
  );
}

function* handleSyndicationMatchProfile() {
  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_PROFILE_HOOK_USED],
    function* ({
      payload: { companyId, companyType, uuid },
    }: SyndicationMatchProfileHookUsed) {
      yield call(loadSyndicationMatchProfile, companyId, companyType, uuid);
    }
  );

  yield takeRequestTrigger(
    [SyndicationActionType.SYNDICATION_MATCH_PROFILE_ANALYTICS_HOOK_USED],
    function* ({
      payload: { companyId, uuid, companyAnalysisId },
    }: SyndicationMatchProfileAnalyticsHookUsed) {
      yield call(
        loadSyndicationMatchProfileAnalytics,
        companyId,
        uuid,
        companyAnalysisId
      );
    }
  );
}

function* loadSyndicationRecommendations(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiSyndicationRecommendationsStateChanged, () =>
    api.syndication.getSyndicationRecommendations(companyId)
  );
}

function* loadSyndicationMatches(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchesStateChanged, () =>
    api.syndication.__DEPRECATED__getSyndicationMatches(companyId)
  );
}

function* loadSyndicationV2Matches(
  companyId: number,
  sectionDeals: SyndicationSectionDeals
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchesStateChanged, () =>
    api.syndication.getSyndicationMatches(companyId, sectionDeals)
  );
}

function* loadSyndicationMatch(companyId: number, uuid: string) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchRequestStateChanged, () =>
    api.syndication.getSyndicationMatch(companyId, uuid)
  );
}

function* loadPreIntroCharts(matchUuid: string, companyAnalysisId?: number) {
  const api: API = yield getAPI();
  yield request(apiPreIntroChartsStateChanged, () =>
    api.syndication.getPreIntroCharts(matchUuid, companyAnalysisId)
  );
}

function* loadSyndicationInsightsCharts(
  actionHook: any,
  companyId: number,
  companyAnalysisId?: number
) {
  const api: API = yield getAPI();
  yield request(actionHook, () => {
    return api.legacy.getSyndicationInsightsCharts(
      companyId,
      companyAnalysisId
    );
  });
}

function* loadSyndicationOnboardingMatches(
  companyId: number,
  companyType: CompanyType[]
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationOnboardingMatchesStateChanged, () =>
    api.syndication.getSyndicationOnboardingMatches(companyId, companyType)
  );
}

function* loadSyndicationConditionsNeedingAcceptance(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiSyndicationConditionsNeedingAcceptanceStateChanged, () =>
    api.syndication.getSyndicationConditionsNeedingAcceptance(companyId)
  );
}

function* loadSyndicationExistingProofFiles(
  companyId: number,
  matchUuid: string
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationExistingProofFilesStateChanged, () =>
    api.syndication.getSyndicationExistingProofFiles(companyId, matchUuid)
  );
}
function* sendSyndicationInvestorShowsInterestInCompany(
  companyId: number,
  matchUuid: string
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationInvestorShowsInterestInCompanyStateChanged, () =>
    api.syndication.sendSyndicationInvestorShowsInterestInCompany(
      companyId,
      matchUuid
    )
  );
}

function* sendSyndicationSendMatchResponse(
  companyId: number,
  companyType: CompanyType[],
  payload: { [id: string]: any }
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationSendMatchResponseStateChanged, () =>
    api.syndication.sendSyndicationSendMatchResponse(
      companyId,
      companyType,
      payload
    )
  );
}

function* sendSyndicationSendSawCard(
  companyId: number,
  companyType: CompanyType[],
  matchUuid: string
) {
  const api: API = yield getAPI();
  api.syndication.sendSyndicationSendSawCard(companyId, companyType, matchUuid);
}

function* sendSyndicationInvestorAcceptsLegalConditions(
  companyId: number,
  payload: { [id: string]: any }
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationConditionsNeedingAcceptanceStateChanged, () =>
    api.syndication.sendSyndicationInvestorAcceptsLegalConditions(
      companyId,
      payload
    )
  );
}

function* sendSyndicationAssociateFileToOutOfFeeProofs(
  companyId: number,
  matchUuid: string,
  createdFileId: number
) {
  const api: API = yield getAPI();
  api.syndication.sendSyndicationAssociateFileToOutOfFeeProofs(
    companyId,
    matchUuid,
    createdFileId
  );
}

function* loadSyndicationOnboardingMatchesForGuest(token: string) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchesForGuestStateChanged, () =>
    api.syndication.getSyndicationOnboardingMatchesForGuest(token)
  );
}

function* loadSyndicationOnboardingResults(
  companyId: number,
  companyType: CompanyType[]
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationOnboardingResultsStateChanged, () =>
    api.syndication.getSyndicationOnboardingResults(companyId, companyType)
  );
}

function* loadSyndicationMatchEvents(companyId: number, matchId: number) {
  const api: API = yield getAPI();
  yield request(
    ({ result }) => apiSyndicationMatchEventsStateChanged({ result, matchId }),
    () => api.syndication.getSyndicationMatchEvents(companyId, matchId)
  );
}
function* loadSyndicationMatchOutReasons() {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchOutReasonsStateChanged, () =>
    api.syndication.getSyndicationMatchOutReasons()
  );
}
function* loadSyndicationLoadOutOfFeeFiles(companyId: number, matchId: number) {
  const api: API = yield getAPI();
  yield request(apiSyndicationLoadOutOfFeeFilesStateChanged, () =>
    api.syndication.getSyndicationLoadOutOfFeeFiles(companyId, matchId)
  );
}

function* loadSyndicationDataRoom(companyId: number) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchDataroomStateChanged, () =>
    api.syndication.getSyndicationDataroomFiles(companyId)
  );
}

function* loadSyndicationMatchProfile(
  companyId: number,
  companyType: CompanyType[],
  uuid: string
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchProfileStateChanged, () =>
    api.syndication.getSyndicationMatchProfile(companyId, companyType, uuid)
  );
}

function* loadSyndicationMatchProfileAnalytics(
  companyId: number,
  uuid: string,
  companyAnalysisId?: number
) {
  const api: API = yield getAPI();
  yield request(apiSyndicationMatchProfileAnalyticsStateChanged, () =>
    api.syndication.getSyndicationMatchProfileAnalytics(
      companyId,
      uuid,
      companyAnalysisId
    )
  );
}

function* loadCompanyPermissions(
  companyId: number,
  adminMatchPermissions = false,
  investorId?: number
) {
  const actionCreator = adminMatchPermissions
    ? permissionsAdminStateChanged
    : permissionsCheckStateChanged;
  if (!investorId) {
    const api: API = yield getAPI();
    yield request(actionCreator, () =>
      api.syndication.getAdminDataroomInsightsPerms(companyId)
    );
  } else if (investorId) {
    const api: API = yield getAPI();
    yield request(actionCreator, () =>
      api.syndication.getDataroomInsightsPerms(companyId, investorId)
    );
  }
}

function* sendMatchStatusNotification(companyId: number, matchIds: number[]) {
  const api: API = yield getAPI();
  yield put(
    apiSyndicationCompanyMatchCreated({
      result: createLoadingResult(),
      companyId,
    })
  );
  try {
    const result = createDataResult(
      yield call(
        api.syndication.updateMatchSendNotification,
        companyId,
        matchIds
      )
    );
    yield put(apiSyndicationCompanyMatchCreated({ result, companyId }));
    yield call(() =>
      toast.success('Email with introduction sent to investor!')
    );
    yield put(adminSuccessfullySentIntros());
  } catch (error) {
    yield call(() => toast.error('Failed to send introductions'));
    yield put(adminFailedToSendIntros());
    throw error;
  }
}

function* loadSyndicationInvestorDetails(companyId: number, matchUuid: string) {
  const api: API = yield getAPI();
  yield request(syndicationInvestorDetailsStateChanged, () =>
    api.syndication.getSyndicationInvestorDetails(companyId, matchUuid)
  );
}

function* transactionDocumentUploadedEmail(
  companyId: number,
  matchUuid: string
) {
  const api: API = yield getAPI();
  yield put(
    transactionDocumentUploadResponse({ result: createLoadingResult() })
  );
  try {
    const result = createDataResult(
      yield call(
        api.syndication.transactionDocumentUploadedEmail,
        companyId,
        matchUuid
      )
    );

    if (result.data.message === 'MATCH_NOT_FOUND')
      call(() => toast.error('Match not found'));
    else if (result.data.message === 'ADDITIONAL_DOCUMENTS')
      call(() => toast.success('Your files were successfully sent'));
    else if (result.data.message !== 'FIRST_DOCUMENT')
      call(() => toast.error('Unspecified error'));

    yield put(transactionDocumentUploadResponse({ result }));
    yield put(transactionDocumentEmailSent({ companyId }));
  } catch (error: any) {
    const result = createErrorResult(error);
    call(() => toast.error('Unspecified error'));
    yield put(transactionDocumentUploadResponse({ result }));
  }
}
