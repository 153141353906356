// How this works:
// This LinkedIn Ads page has all the conversions we want to track
// https://www.linkedin.com/campaignmanager/accounts/506425485/website-tracking/conversions
import { getSubdomain } from '@hum/icm-app/src/utils';

// Last updated 2021-08-10
// Current events we're tracking
export enum LinkedInAdsConversions {
  companySignupSubmit = '4618332',
  companySignupPageview = '4424892',
  marketingSiteCtaClicked = '', // Not used right now but keeping as placeholder
  companyProfileComplete = '4618340',
  investorSignupSubmit = '4618348',
  investorSignupPageview = '4618356',
}

export const trackLinkedInAdsConversion = (
  conversionId: LinkedInAdsConversions
) => {
  const lintrk = window['lintrk'];
  const { isPrimarySubdomain, isDemo } = getSubdomain();
  if (lintrk && isPrimarySubdomain && !isDemo) {
    lintrk('track', { conversion_id: conversionId });
  }
};
