import {
  select,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { takeRequestTrigger, loadCompanyFiles, getAPI } from './utils';
import * as URL from 'url';
import { Company, FileStatus } from '@hum/types';
import {
  AppState,
  Pagination,
  FilterCategory,
  QueryStringEntry,
  getUserCompanyId,
} from '../state';
import { client as backend } from '@hum/common/src/api/client';
import {
  BaseCompanyAction,
  createDataResult,
  createErrorResult,
  createLoadingResult,
} from '@hum/common';
import { request } from '@hum/common/src/ducks/sagas/util';
import { API } from '../backend/api';
import {
  ActionType,
  ConnectorTenantDeleted,
  ExternalServiceConnected,
  connectorsRequestStateChanged,
  apiConnectorSavedSuccessfully,
  availableConnectorsRequestStateChanged,
  apiInvestorCompaniesLoaded,
  apiInvestorAvailableCompaniesLoaded,
  ChartsHookUsed,
  chartsRequestStateChanged,
  CompanyContactHookUsed,
  companyContactRequestChanged,
  companyRequestStateChanged,
  apiCompaniesRequestStateChanged,
  CompanyListFiltersChanged,
  apiFileRemoveStateChanged,
  apiFileUpdateStateChanged,
  CompanyAnalysisAction,
  BaseConnectorAction,
  ApiFileRemoved,
  ApiFileUpdated,
  companyDataAssetsRequestStateChanged,
  apiDeletedConnector,
  SyndicationActionType,
  apiWhitelabelingRequestStateChanged,
  adminCompanyInvestorListStateChanged,
  PrincipalInvestmentActionType,
  companyAnalysisRequestStateChanged,
  companyAnalysisWarningsRequestStateChanged,
  CompanyAnalysisFrozen,
  frozenCompanyAnalysisStateChanged,
  companyPublicProfileStateChanged,
  CompanyPublicProfileHookUsed,
  AdminActionType,
} from '@hum/icm-app/src/actions';
import { toast } from '@hum/common/src/modules/toast';
import {
  getSelectedFilters,
  createQueryString,
} from '@hum/icm-app/src/pages/admin/LeftSidebar/createQueryString';

import { mainBrandingSaga } from './branding';
/**
 * Saga for handling _user facing_ side effects
 */

export function* appSaga() {
  if (typeof window === 'undefined') {
    return;
  }

  yield fork(handleCompanyFiles);
  yield fork(handleCompanyListFilters);
  yield fork(handleCompanies);
  yield fork(handleCompany);
  yield fork(handleCompanyContact);
  yield fork(handleCharts);
  yield fork(handleConnectors);
  yield fork(handlePorfolioCompanies);
  yield fork(handlePorfolioAvailableCompanies);
  yield fork(handleCompanyDataAssets);
  yield fork(handleAvailableConnectors);
  yield fork(handleDeleteLinkedAccount);
  yield fork(handleWhitelabeling);
  yield fork(mainBrandingSaga);
  yield fork(costOfEquitySaga);
  yield fork(handleCompanyAnalyses);
}

function* handleConnectors() {
  yield takeEvery(
    ActionType.EXTERNAL_SERVICE_CONNECTED,
    function* ({
      payload: {
        companyId,
        displayName,
        connectorType,
        airbyteEnabled,
        params,
      },
    }: ExternalServiceConnected) {
      const api: API = yield getAPI();

      try {
        yield call(
          api.legacy.addConnector,
          companyId,
          connectorType,
          airbyteEnabled,
          params
        );

        yield put(apiConnectorSavedSuccessfully({ companyId, connectorType }));
        toast.success(`${displayName} successfuly connected!`);
      } catch (e: any) {
        toast.error(String(e.message || `Failed to connect ${displayName}`));
      }
    }
  );

  yield takeRequestTrigger(
    [ActionType.CONNECTOR_TENANT_DELETED],
    function* ({
      payload: { companyId, connectorId, tenantId },
    }: ConnectorTenantDeleted) {
      const api: API = yield getAPI();
      try {
        yield call(api.legacy.deleteTenant, {
          companyId,
          connectorId,
          tenantId,
        });
        yield put(apiDeletedConnector({ companyId, connectorId }));
      } catch (e: any) {
        toast.error(String(e.message || `Failed delete tenant ${tenantId}`));
      }
    }
  );

  yield takeEvery(
    [
      ActionType.CONNECTORS_HOOK_USED,
      ActionType.API_CONNECTOR_SAVED_SUCCESSFULLY,
      ActionType.API_CONNECTOR_DISCONNECTED,
      ActionType.API_DELETED_CONNECTOR,
    ],
    function* ({ payload }: BaseCompanyAction<any>) {
      const companyId = payload.companyId || getUserCompanyId(yield select());
      const api: API = yield getAPI();

      yield request(connectorsRequestStateChanged, async () => {
        return await api.legacy.getConnectors(companyId);
      });
    }
  );
}

function* handlePorfolioCompanies() {
  // this is going to be triggered with dispatch(investorCompaniesHookUsed)
  yield takeRequestTrigger(
    [
      ActionType.INVESTOR_COMPANIES_HOOK_USED,
      ActionType.INVESTOR_COMPANIES_REMOVED,
      ActionType.INVESTOR_COMPANIES_ADDED,
    ],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      yield call(loadPortfolioCompanies, companyId);
    }
  );
  yield takeRequestTrigger(
    [ActionType.ADMIN_COMPANY_INVESTOR_LIST_REQUESTED],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      yield call(loadCompanyInvestors, companyId);
    }
  );
}

function* loadPortfolioCompanies(companyId: number) {
  const api: API = yield getAPI();
  try {
    const companies = yield call(api.legacy.getPortfolioCompanies, companyId);
    // this then triggers the action API_INVESTOR_COMPANIES_LOADED
    yield put(apiInvestorCompaniesLoaded({ companyId, companies }));
  } catch (error: any) {
    toast.error(error?.message || 'Failed to load investor companies');
    yield put(apiInvestorCompaniesLoaded({ companyId, companies: [] }));
  }
}

function* handlePorfolioAvailableCompanies() {
  yield takeRequestTrigger(
    [ActionType.INVESTOR_AVAILABLE_COMPANIES_HOOK_USED],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      yield call(loadAvailableCompanies, companyId);
    }
  );
}

function* loadAvailableCompanies(companyId: number) {
  const api: API = yield getAPI();
  try {
    const companies = yield call(
      api.legacy.getAvailablePortfolioCompanies,
      companyId
    );
    // this then triggers the action API_INVESTOR_COMPANIES_LOADED
    yield put(apiInvestorAvailableCompaniesLoaded({ companyId, companies }));
  } catch (error: any) {
    toast.error(error?.message || 'Failed to load investor companies');
    yield put(
      apiInvestorAvailableCompaniesLoaded({ companyId, companies: [] })
    );
  }
}

function* loadCompanyInvestors(companyId: number) {
  yield call(loadInvestors, companyId);
}

function* loadInvestors(companyId: number) {
  const api: API = yield getAPI();
  yield request(adminCompanyInvestorListStateChanged, () => {
    return api.legacy.getCompanyInvestors(companyId);
  });
}

function* handleCharts() {
  yield takeRequestTrigger(
    ActionType.CHARTS_HOOK_USED,
    function* (action: ChartsHookUsed) {
      yield call(
        loadCharts,
        action.payload.companyId,
        action.payload.tabName,
        action.payload.companyAnalysisId
      );
    }
  );
}

function* loadCharts(
  companyId: number,
  tabName: string,
  companyAnalysisId?: number
) {
  const api: API = yield getAPI();
  yield request(chartsRequestStateChanged, () => {
    return api.legacy.getChartsByTabName(companyId, tabName, companyAnalysisId);
  });
}

function* handleCompanies() {
  yield takeRequestTrigger(
    [
      ActionType.API_COMPANY_APPLICATION_CREATED,
      AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED_SUCCESS,
      ActionType.API_PORTFOLIO_INVESTOR_CREATED,
      SyndicationActionType.API_SYNDICATION_INVESTOR_CREATED,
      PrincipalInvestmentActionType.API_BALANCE_SHEET_PARTNER_CREATED,
    ],
    function* () {
      const state: AppState = yield select();
      const payload = { filters: state.companyListFilters };
      // send filters as payload
      yield call(loadCompanies, payload.filters);
    }
  );
}

function* handleCompanyListFilters() {
  yield takeRequestTrigger(
    ActionType.COMPANY_LIST_FILTERS_CHANGED,
    function* (action: CompanyListFiltersChanged) {
      // note/question: sending action.payload.filters becomes undefined when receiving it at loadCompanies
      // but sending just action.payload works :/ why?
      yield call(loadCompanies, action.payload.filters);
    }
  );
}

function* handleWhitelabeling() {
  const api: API = yield getAPI();

  // first part is for localhost & debugging, second part is for production. test: investor_acme_inc_3
  const subdomain =
    (URL.parse(window.location.href, true).query as any)?.subdomain ||
    window.location.hostname.split('.').shift();

  yield request(apiWhitelabelingRequestStateChanged, () => {
    return api.legacy.getCompanyTheme(subdomain);
  });
}

export function* loadCompanies(filters: FilterCategory[]) {
  const api: API = yield getAPI();
  yield request(apiCompaniesRequestStateChanged, () => {
    return getCompanies(filters, api);
  });
}

async function getCompanies(
  filters: FilterCategory[],
  api: API
): Promise<{ companies: Company[]; pagination: Pagination }> {
  try {
    const selectedFilters = getSelectedFilters(filters);
    const filterQuery = createQueryString(
      selectedFilters as QueryStringEntry[]
    );

    return api.legacy.searchCompanies(filterQuery);
  } catch (error: any) {
    toast.error(String(error.message || `Failed to get companies`));
    // return default state initial state if failed to get companies
    return {
      companies: [],
      pagination: {
        pageCount: 0,
        currentPage: 0,
        results: 0,
        pageSize: 0,
      },
    };
  }
}

function* handleCompany() {
  yield takeRequestTrigger(
    [
      ActionType.ADMIN_EDIT_COMPANY_FORM_CLOSED,
      ActionType.COMPANY_HOOK_USED,
      ActionType.INVESTOR_FORM_SAVED,
      ActionType.API_ACCEPTED_NDA,
      ActionType.API_ACCEPTED_TOS,
      ActionType.API_PORTFOLIO_INVESTOR_CREATED,
      ActionType.API_UPDATED_COMPANY_APPLICATION,
      SyndicationActionType.API_SYNDICATION_COMPANY_UPDATED,
      SyndicationActionType.API_SYNDICATION_COMPANY_INFORMATION_STATE_CHANGED,
      PrincipalInvestmentActionType.BALANCE_SHEET_PARTNER_FORM_SAVED,
      AdminActionType.ADMIN_COMPANY_SYNDICATION_DISABLED_TOGGLED,
    ],
    function* (action: BaseCompanyAction<any>) {
      const state: AppState = yield select();
      yield call(
        loadCurrentCompany,
        action.payload.companyId || getUserCompanyId(state)
      );
    }
  );

  yield takeRequestTrigger(
    [ActionType.COMPANY_PUBLIC_PROFILE_HOOK_USED],
    function* (action: CompanyPublicProfileHookUsed) {
      yield call(loadCompanyPublicProfile, action.payload.companyId);
    }
  );
}

function* loadCurrentCompany(companyId: number) {
  const api: API = yield getAPI();
  yield request(companyRequestStateChanged, () => {
    return api.legacy.getCompany(companyId);
  });
}

function* loadCompanyPublicProfile(companyId: number) {
  const api: API = yield getAPI();
  try {
    yield put(
      companyPublicProfileStateChanged({ result: createLoadingResult() })
    );
    const res = yield call(api.legacy.getCompanyPublicProfile, companyId);
    if (res) {
      yield put(
        companyPublicProfileStateChanged({ result: createDataResult(res) })
      );
    }
  } catch (error) {
    yield put(
      companyPublicProfileStateChanged({
        result: createErrorResult(error as Error),
      })
    );
    throw error;
  }
}

function* handleCompanyContact() {
  yield takeRequestTrigger(
    [
      ActionType.COMPANY_CONTACT_HOOK_USED,
      ActionType.ADMIN_EDIT_COMPANY_FORM_CLOSED,
    ],
    function* (action: CompanyContactHookUsed) {
      const api: API = yield getAPI();
      yield request(companyContactRequestChanged, () => {
        return api.legacy.getCompanyContact(action.payload.companyId);
      });
    }
  );
}

function* handleCompanyFiles() {
  yield takeRequestTrigger(
    [
      ActionType.COMPANY_FILES_HOOK_USED,
      ActionType.FILE_MANAGER_HOOK_USED,
      ActionType.API_FILE_CREATED,
      ActionType.API_FILE_UPLOADED,
      SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_EMAIL_SENT,
    ],
    function* (action: BaseCompanyAction<any>) {
      yield call(loadCompanyFiles, action.payload.companyId);
    }
  );
  yield takeRequestTrigger(
    [ActionType.API_FILE_REMOVED],
    function* ({ payload: { companyId, fileId, silent } }: ApiFileRemoved) {
      yield call(triggerFileDeletion, companyId, fileId, silent);
    }
  );
  yield takeRequestTrigger(
    [ActionType.API_FILE_UPDATED],
    function* ({ payload: { companyId, fileId, status } }: ApiFileUpdated) {
      yield call(triggerFileUpdate, companyId, fileId, status);
    }
  );
}

function* handleCompanyDataAssets() {
  yield takeRequestTrigger(
    [ActionType.COMPANY_DATA_ASSETS_HOOK_USED],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      yield call(loadDataAssets, companyId);
    }
  );
}

function* loadDataAssets(companyId: number) {
  const api: API = yield getAPI();
  yield request(companyDataAssetsRequestStateChanged, () =>
    api.legacy.getCompanyDataAssets(companyId)
  );
}

function* triggerFileDeletion(
  companyId: number,
  fileId: number,
  silent: boolean = false
) {
  const api: API = yield getAPI();
  yield request(
    (payload) => {
      if (payload.result.loaded) {
        if (payload.result.error) {
          toast.error(
            String(payload.result.error.message || `Failed to remove file`)
          );
        } else {
          toast.success('Successfuly removed file');
        }
      }
      return apiFileRemoveStateChanged({ ...payload, silent });
    },
    () => {
      return api.legacy.deleteFile(companyId, fileId);
    }
  );
  yield call(loadCompanyFiles, companyId);
}

function* triggerFileUpdate(
  companyId: number,
  fileId: number,
  status: FileStatus
) {
  const api: API = yield getAPI();
  yield request(apiFileUpdateStateChanged, () => {
    return api.legacy.setFileStatus(companyId, fileId, status);
  });
  yield call(loadCompanyFiles, companyId);
}

function* handleAvailableConnectors() {
  yield takeRequestTrigger(
    [ActionType.AVAILABLE_CONNECTORS_HOOK_USED],
    function* () {
      yield call(loadAvailableConnectors);
    }
  );
}

function* loadAvailableConnectors() {
  const api: API = yield getAPI();
  yield request(availableConnectorsRequestStateChanged, () =>
    api.legacy.getAvailableConnectors()
  );
}

function* handleDeleteLinkedAccount() {
  yield takeRequestTrigger(
    [ActionType.LINKED_ACCOUNT_DELETE_BUTTON_CLICKED],
    function* ({
      payload: { companyId, connectorId },
    }: BaseConnectorAction<any>) {
      // TODO: use remove special quickbooks case when DELETE /companies/:companyId/connectors/:connectorId is supported
      // for the service.
      const uri = `/companies/${companyId}/connectors/${connectorId}`;

      yield call(backend.delete, uri, {
        withCredentials: true,
      });

      yield put(apiDeletedConnector({ companyId, connectorId }));
    }
  );
}

function* costOfEquitySaga() {
  yield takeRequestTrigger(
    [ActionType.COST_OF_EQUITY_CHANGED],
    function* ({ payload }) {
      const api: API = yield getAPI();
      yield call(api.legacy.calculateCostOfEquity, payload);
    }
  );
}

function* handleCompanyAnalyses() {
  yield takeLatest(
    ActionType.COMPANY_ANALYSES_HOOK_USED,
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      const api: API = yield getAPI();
      yield request(companyAnalysisRequestStateChanged, () =>
        api.legacy.getCompanyAnalyses(companyId)
      );
    }
  );

  yield takeLatest(
    ActionType.COMPANY_ANALYSIS_WARNINGS_HOOK_USED,
    function* ({
      payload: { companyId, companyAnalysisId },
    }: CompanyAnalysisAction<any>) {
      const api: API = yield getAPI();
      yield request(companyAnalysisWarningsRequestStateChanged, () =>
        api.legacy.getCompanyAnalysisWarnings(companyId, companyAnalysisId)
      );
    }
  );

  yield takeEvery(
    ActionType.COMPANY_ANALYSIS_FROZEN,
    function* ({
      payload: { companyId, frozenCompanyAnalysisId },
    }: CompanyAnalysisFrozen) {
      const api: API = yield getAPI();
      yield request(frozenCompanyAnalysisStateChanged, async () => {
        const response = await api.legacy.updateCompany(companyId, {
          frozenCompanyAnalysisId:
            frozenCompanyAnalysisId === undefined
              ? -1
              : frozenCompanyAnalysisId,
        });
        if (response) {
          return {
            companyId: response['id'],
            frozenCompanyAnalysisId: response['frozen_company_analysis_id'],
          };
        } else {
          throw 'Unable to freeze company analytics';
        }
      });
    }
  );
}
