import { createStore, applyMiddleware, compose, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import { AppState, INITIAL_APP_STATE } from './state';
import { API, createAPI } from './backend';
import { History } from 'history';
import { API_CONTEXT_NS, HISTORY_CONTEXT_NS } from './sagas/utils';
import { domHistory } from '@hum/common';
import { client } from '@hum/common/src/api/client';

export type CreateAppStoreProps = {
  api: API;
  history: History;
  initialState?: Partial<AppState>;
  middleware?: Middleware[];
};

export const createAppStore = ({
  api,
  history,
  initialState = {},
  middleware = [],
}: CreateAppStoreProps) => {
  // instructions: https://github.com/zalmoxisus/redux-devtools-extension
  const reduxDevtoolsMiddleware =
    window['__REDUX_DEVTOOLS_EXTENSION__'] &&
    window['__REDUX_DEVTOOLS_EXTENSION__']();

  const sagaMiddleware = createSagaMiddleware({
    context: {
      [API_CONTEXT_NS]: api,
      [HISTORY_CONTEXT_NS]: history,
    },
  });

  const reduxMiddleware = [
    applyMiddleware(sagaMiddleware, ...middleware),
    reduxDevtoolsMiddleware,
  ].filter(Boolean);

  const store = createStore(
    rootReducer,
    {
      ...INITIAL_APP_STATE,
      ...initialState,
    },
    // @ts-ignore
    compose(...reduxMiddleware)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

// TODO: delete me, this is legacy code and we shouldn't have globals, but this
// is necessary for things like `confirm`, `fail`, and `succeed`
export const __do_not_use_me_api = createAPI({
  client,
});

export const __do_not_use_me_unless_you_know_what_you_are_doing__store = createAppStore(
  {
    api: __do_not_use_me_api,
    history: domHistory,
  }
);

// for debugging
if (typeof window !== 'undefined') {
  // dev utils
  // eslint-disable-next-line
  window['dev'] = {
    get appState() {
      return __do_not_use_me_unless_you_know_what_you_are_doing__store.getState();
    },
  };
}
