import { fork, takeEvery, call, delay } from 'redux-saga/effects';
import {
  getAPI,
  loadCompanyFiles,
  loadCurrentCompany,
  takeRequestTrigger,
} from './utils';
import { request } from '@hum/common/src/ducks/sagas/util';
import {
  DocusignActionType,
  DocusignServiceConnected,
  docusignConnectorLoaded,
  ActionType,
  docusignEnvelopeRequestStateChanged,
} from '../actions';
import { Company } from '@hum/types';
import { DocusignMNDAStatus } from '../state';
import { Result, BaseCompanyAction } from '@hum/common';
import { toast } from '@hum/common/src/modules/toast';
import { client as backend } from '@hum/common/src/api/client';
import { getMNDADocumentStatus } from '@hum/icm-app/src/hooks/useMNDA';
import { API } from '../backend';

export function* docusignSaga() {
  yield fork(handleDocusignConnected);
  yield fork(handleDocuSignConnector);
  yield fork(handleDocusign);
}

function* handleDocusignConnected() {
  yield takeEvery(
    DocusignActionType.DOCUSIGN_SERVICE_CONNECTED,
    function* ({ payload: { params } }: DocusignServiceConnected) {
      const api: API = yield getAPI();
      try {
        yield call(api.legacy.addDocuSignConnector, params);
        toast.success(`DocuSign successfully connected!`);
        yield call(loadDocuSignConnector);
      } catch (e: any) {
        toast.error(String(e.message || `Failed to connect DocuSign`));
      }
    }
  );
}

function* handleDocuSignConnector() {
  yield takeRequestTrigger(
    [DocusignActionType.DOCUSIGN_CONNECTOR_HOOK_USED],
    function* () {
      yield call(loadDocuSignConnector);
    }
  );
}

function* loadDocuSignConnector() {
  const api: API = yield getAPI();
  yield request(docusignConnectorLoaded, () => {
    return api.legacy.getDocuSignConnector();
  });
}

function* handleDocusign() {
  yield takeEvery(
    [ActionType.DOCUSIGN_ENVELOPE_HOOK_USED],
    function* ({ payload: { companyId } }: BaseCompanyAction<any>) {
      if (isNaN(companyId)) {
        return;
      }

      function* load(): any {
        const company: Result<Company> = yield call(loadCurrentCompany);

        const envelope = yield request(
          docusignEnvelopeRequestStateChanged,
          async () => {
            // TODO: fix return type here
            return (await backend.get(
              `/companies/${companyId}/docusign/envelope`,
              {
                withCredentials: true,
              }
            )) as any;
          }
        );

        const status: DocusignMNDAStatus = getMNDADocumentStatus({
          acceptedNDA: company.data?.acceptedNDA,
          acceptedNDAOn: company.data?.acceptedNDAOn,
          envelope,
        });

        // if file is still pending, then continue to load until it's not pending anymore.
        if (status === DocusignMNDAStatus.WaitingForFile) {
          yield delay(1000 * 4);
          yield call(load);
        } else if (company.data) {
          // re-load files so that MNDA file shows up. Otherwise exception is thrown.
          yield call(loadCompanyFiles, company.data!.id);
        }
      }

      yield call(load);
    }
  );
}
