import { createUrlFromTemplate } from './route-utils';

export enum Routes {
  INVITE = '/invite', // reclaim invites to ICM from email
  LOGIN = '/login', // login page
  LOGOUT = '/logout', // logout page, you cannot technically be here but you can visit it to logout, will take you to login almost immediately
  RECOVER = '/recover', // request a password recovery
  RAISE_OR_INVEST = '/raise-or-invest', // decide if you wan't to sign up as company (raise) or investor (invest)
  SIGNUP = '/signup', // sign up as a company
  ABOUT_YOUR_BUSINESS = '/signup/about-your-business', // onboarding v5 – Tell us about your business
  REVIEW_AND_CONFIRM = '/signup/review-and-confirm', // onboarding v5 – sales support – review your signup information if you were added via admin page
  FUNDRAISING_HISTORY = '/signup/fundraising-history', // onboarding v5 – Tell us about your fundraising history
  SHARE_YOUR_GOALS = '/signup/share-your-goals', // onboarding v5 – Share your goals
  COMPLETE_SIGNUP = '/signup/complete', // small graphic, initial signup has been completed
  QUALIFY = '/qualify', // legacy URL, will redirect to /signup
  SIGNUP_INVESTOR = '/signup/investor', // signup as an investor
  SIGNUP_SYNDICATION_INVESTOR = `/signup/syndication-investor`, // signup as an investor, specifically a syndication investor
  SIGNUP_PORTFOLIO_INVESTOR = `/signup/portfolio-investor`, // signup as an investor, specifically a portfolio investor
  SIGNUP_COMPANY = '/signup/company', // literally the same as regular signup but used when users come from main branding website
  SET_PASSWORD = '/set-password', // when you create your account, you get an email to set your password, this is also used when you forgot your password, you only get here via a link in an email
  VERIFY_EMAIL = '/verify-email', // verify that the email you registered is yours, you only get here via a link in an email
  UNSUPPORTED_COUNTRY = '/company-requirement', //when user pick an unsupported country in Tell about your business
  ROOT = '/', // main path of ICM, redirects you to the right page if you have a session or login/signup if you don't

  // -- COMPANY SETTINGS ROUTE START --
  MANAGE_TEAM = '/settings/team', // settings page, manage your team
  SUBDOMAIN = '/settings/subdomain', // settings page, change your subdomain, only for portfolio investors
  BRANDING = '/settings/branding', // settings page, change your branding and logo, only for portfolio investors
  SETTINGS_ACCOUNT = `/settings/account`, // settings page, change details on your account, for everyone
  SETTINGS_DOCUMENTS = `/settings/documents`, // settings page, documents such as MNDA, for companies
  SETTINGS_FUNDING_PROFILE = `/settings/funding-profile`, // settings page, TBD description
  SETTINGS_SYNDICATION_ARCHIVE = `/settings/archive`, // settings page, TBD description
  SETTINGS_SYNDICATION_ARCHIVE_DETAIL = `/settings/archive/detail`, // settings page, TBD description
  SETTINGS = '/settings', // root of settings page
  // -- COMPANY SETTINGS ROUTE END --

  GATEWAY = `/gateway`, // redirect companies to the current version of onboarding, can be ignored
  ONBOARDING_INDEX = `/onboarding`, // onboarding for companies
  INSIGHTS = `/insights`, // missing description
  INSIGHTS_FINANCIAL_ANALYSIS = `/insights/financial_analysis`, // show financial analysis charts, for companies
  INSIGHTS_CHART_TAB = `/insights/:chartTabName`, // show financial analysis charts, for companies
  ADMIN = `/admin`, // admin page, for ICM admins
  ADMIN_COMPANY_ANALYTICS = `/admin/:companyId/analytics`, // admin page, see charts for a company, for ICM admins
  VERIFY_INFO = `/verify-information`, // the original version of review-and-confirm, TODO: delete this URL
  SYNDICATION = '/syndication', // missing description
  SYNDICATION_INVESTOR_MATCHES = `/syndication/:sectionDealsParams`, // missing description
  SYNDICATION_LIVE_DEALS = `/syndication/live-deals`, // missing description
  SYNDICATION_NEW_DEALS = `/syndication/new-deals`, // missing description
  SYNDICATION_INVESTOR_MATCH = `/syndication/match`, // missing description
  SYNDICATION_INVESTOR_MATCH_DATA_ROOM = `/syndication/match/data-room`, // missing description
  SYNDICATION_INVESTOR_MATCH_INSIGHTS = `/syndication/match/insights`, // missing description
  SYNDICATION_INVESTOR_MATCH_INTERESTED = `/syndication/match/interested`, // missing description
  SYNDICATION_INVESTOR_MATCH_FEEDBACK = `/syndication/match/feedback`, // missing description
  FUNDING_OPPORTUNITIES = `/funding-opportunities`, // missing description
  FUNDING_OPPORTUNITIES_INVESTOR = `/funding-opportunities/investor`, // missing description
  PROFILE = `/profile`, // see the profile of a company or investor, requires the UUID of the match as argument, otherwise it won't show anything

  // -- COMPANY ACTIVATION ROUTE START --
  COMPANY_ACTIVATION_ROOT = '/company-activation', // Company activation v4 / root / activation is where the company makes a "second onboarding" and fills more info

  // TODO - rename to /dashboard when show-new-dashboard is turned on
  COMPANY_ACTIVATION_GET_STARTED = '/company-activation/get-started', // Company activation v4 / Initial screen
  COMPANY_ACTIVATION_DOCUMENT_DATAROOM = '/company-activation/document-dataroom', // Company activation v4 / Upload documents
  COMPANY_ACTIVATION_CONNECTORS_CONNECT = '/company-activation/connectors/:platformType/connect', // Company activation v5 / Connect connectors
  COMPANY_ACTIVATION_CONNECTORS = '/company-activation/connectors', // Company activation v4 / Connect connectors
  COMPANY_ACTIVATION_CONNECTORS_PAYMENT = '/company-activation/connectors/payment-processor', // Company activation v4 / Connect connectors / Payment processors
  COMPANY_ACTIVATION_CONNECTORS_ACCOUNTING = '/company-activation/connectors/accounting-system', // Company activation v4 / Connect connectors / Accounting systems
  COMPANY_ACTIVATION_CONNECTORS_BANK = '/company-activation/connectors/bank', // Company activation v4 / Connect connectors / Bank account
  COMPANY_ACTIVATION_PUBLIC_PROFILE = '/company-activation/public-profile', // Company activation v4 / Preview your public profile
  // -- COMPANY ACTIVATION ROUTE END --

  LABEL_MAPPER = `/label-mapper`, // missing description
  COMPANY_PUBLIC_PROFILE = `/public/:token`, // See the public profile of a company

  // -- PORTFOLIO MONITORING ROUTE START --
  INVITE_COMPANIES = `/portfolio/invites`, // Invite a company to join you on ICM
  PORTFOLIO_COMPANY = `/portfolio/:companyId`, // See the details of a given company in your portfolio
  PORTFOLIO_COMPANIES = `/portfolio`, // See the list of all companies in your portfolio
  PORTFOLIO_MONITORING = `/portfolio-monitoring`, // Main page for portfolio monitoring / dashboard
  PORTFOLIO_MONITORING_ONBOARDING = `/portfolio-monitoring/onboarding`, // Invite / Connect / Explore cards
  PORTFOLIO_MONITORING_MANAGE_COMPANIES = `/portfolio-monitoring/manage-companies`, // Manage the companies in your portfolio
  PORTFOLIO_MONITORING_PREVIEW_INVITATION = `/portfolio-monitoring/preview-invitation`, // See a preview of the email that companies are getting
  PORTFOLIO_MONITORING_COMPANY = `/portfolio-monitoring/:companyId`, // See the details of a given company in your portfolio
  // -- PORTFOLIO MONITORING ROUTE END --

  // -- COMPANY ONBOARDING V4 ROUTE START --
  ONBOARDING_V4 = `/get-started`, // Main onboarding v4 page, will take you to the correct onboarding v4 step
  ONBOARDING_V4_SET_PASSWORD = `/setup/set-password`, // Onboarding v4 / Set password, you come here from the set password email
  ONBOARDING_V4_MNDA = `/get-started/mnda`, // Onboarding v4 / Sign the MNDA
  ONBOARDING_V4_BASIC_INFO = `/get-started/user-info`, // Onboarding v4 / Tell us about yourself (first name, last name, position/role)
  ONBOARDING_V4_COMPANY_INFO = `/get-started/company-info`, // Onboarding v4 / Tell us about your business
  ONBOARDING_V4_CONNECTORS = `/get-started/connectors`, // Onboarding v4 / Main onboarding connectors screen
  ONBOARDING_V4_CONNECTORS_CONNECTED = `/get-started/linked-accounts/:name`, // Onboarding v4 / Connect a special service (quickbooks, etc)
  ONBOARDING_V4_FEES = `/get-started/fees`, // Onboarding v4 / Accept the 2% fee
  ONBOARDING_V4_ABOUT_YOU = `/get-started/about-you`, // Onboarding v4 / Share the financial overview of your company
  ONBOARDING_V4_FUNDRAISING_GOALS = `/get-started/fundraising-goals`, // Onboarding v4 / Share your fundraising goals
  ONBOARDING_V4_COMPLETED = `/get-started/completed`, // Onboarding v4 / Onboarding completed
  SIGNUP_V4_COMPLETED = `/signup-completed`, // Onboarding v4 / Signup completed
  // -- COMPANY ONBOARDING V4 ROUTE END --

  // -- COMPANY ONBOARDING V5 ROUTE START --
  ONBOARDING_V5 = `/get-started`, // Company Onboarding v5 / After a new user comes to ICM, they land here and this screen decides what step they need to go next
  ONBOARDING_V5_OVERVIEW = `/get-started/overview`, // Company Onboarding v5 / Fill financial overview for company
  ONBOARDING_V5_CONNECT = `/get-started/connect`, // Company Onboarding v5 / Fill financial overview for company
  ONBOARDING_V5_COMPLETED = `/get-started/completed`, // Company Onboarding v5 / Onboarding completed / note, this is the same as ONBOARDING_V4_COMPLETED
  ONBOARDING_V5_MNDA = `/get-started/mnda`, // Company Onboarding v5 / Accept confidentiality agreement
  ONBOARDING_V5_UPLOAD_FILES = `/get-started/upload-files`, // HL M2.5 v3 / Upload files as screen instead of modal
  // -- COMPANY ONBOARDING V5 ROUTE END --

  // -- CONNECTOR SPECIAL PAGES ROUTE START --
  CONNECT = `/connect`, // redirect URL for connectors/services, for companies, you'll only see this for a second on specific connectors before going back to connect page
  CONNECT_FRESHBOOKS = `/connect/freshbooks`,
  CONNECT_PAYPAL = `/connect/paypal`,
  CONNECT_QUICKBOOKS = `/connect/quickbooks`,
  CONNECT_SHOPIFY = `/connect/shopify`,
  CONNECT_SHOPIFY_INSTALL = `/connect/shopify/install`,
  CONNECT_SQUARE = `/connect/square`,
  CONNECT_STRIPE = `/connect/stripe`,
  CONNECT_XERO = `/connect/xero`,
  // -- CONNECTOR SPECIAL PAGES ROUTE END --

  SMARTRAISE = `/smartraise`, // smartraise
  INTRODUCTIONS = '/introductions', // smartmatch
  INTRODUCTIONS_INVESTOR = '/introductions/investor', // smartmatch

  MAFI_CONNECT = `/mafi-connect`, // mafi connect page
  COMPANY_PROFILE_V2 = `/company/:companyId/profile`, // company profile v2
}

export const routeCreators = {
  [Routes.ONBOARDING_V4_CONNECTORS_CONNECTED]: (
    connectorName: string,
    query: Record<string, string> = {}
  ) =>
    createUrlFromTemplate(
      Routes.ONBOARDING_V4_CONNECTORS_CONNECTED,
      {
        name: connectorName,
      },
      query
    ),
  [Routes.ONBOARDING_V4_COMPANY_INFO]: () => Routes.ONBOARDING_V4_COMPANY_INFO,
  [Routes.ONBOARDING_V4_MNDA]: () => Routes.ONBOARDING_V4_MNDA,
  [Routes.ONBOARDING_V4_CONNECTORS]: () => Routes.ONBOARDING_V4_CONNECTORS,
  [Routes.ONBOARDING_V4_FEES]: () => Routes.ONBOARDING_V4_FEES,
  [Routes.ONBOARDING_V4_COMPLETED]: () => Routes.ONBOARDING_V4_COMPLETED,
};

export const OnboardingV5Routes = {
  [Routes.ONBOARDING_V5_OVERVIEW]: () => Routes.ONBOARDING_V5_OVERVIEW,
  [Routes.ONBOARDING_V5_CONNECT]: () => Routes.ONBOARDING_V5_CONNECT,
  [Routes.ONBOARDING_V5_COMPLETED]: () => Routes.ONBOARDING_V5_COMPLETED,
  [Routes.ONBOARDING_V5_MNDA]: () => Routes.ONBOARDING_V5_MNDA,
};
