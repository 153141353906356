import { call, fork } from 'redux-saga/effects';
import { getAPI, takeRequestTrigger } from './utils';
import {
  PortfolioMonitoringActionType,
  PM_InvitedCompaniesUsed,
  PM_invitedCompaniesStateChanged,
  PM_SourceCsvFileUploaded,
  PM_InviteCreated,
  PM_CompanyInviteListUsed,
  PM_companyInviteListStateChanged,
  PM_ShowOnboardingHookUsed,
  PM_showOnboardingStateChanged,
  PM_PortfolioCompanyInviteUrlHookUsed,
  PM_portfolioCompanyInviteUrlStateChanged,
  PM_DashboardHookUsed,
  PM_dashboardStateChanged,
  PM_portfolioCompaniesStateChanged,
  PM_ConnectorsStatusHookUsed,
  PM_connectorsStatusChanged,
} from '../actions';
import { request } from '@hum/common/src/ducks/sagas/util';
import { API } from '../backend';

export function* portfolioMonitoringSaga() {
  yield fork(handleCompanyPrincipalInvestment); // todo: move to principal-investment.ts
  yield fork(handleCompanyInviteList);
}

function* handleCompanyPrincipalInvestment() {
  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_INVITED_COMPANIES_USED],
    function* ({ payload: { companyId } }: PM_InvitedCompaniesUsed) {
      yield call(loadInvitedCompanies, companyId);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_SOURCE_CSV_FILE_UPLOADED],
    function* ({ payload: { companyId, fileId } }: PM_SourceCsvFileUploaded) {
      yield call(bulkInvestorInvites, companyId, fileId);
    }
  );
  yield takeRequestTrigger(
    [
      PortfolioMonitoringActionType.PM_INVITE_CREATED,
      PortfolioMonitoringActionType.PM_INVITE_UPDATED,
      PortfolioMonitoringActionType.PM_BULK_STATUS_UPDATED,
    ],
    function* ({ payload: { companyId } }: PM_InviteCreated) {
      yield call(loadInvitedCompanies, companyId);
    }
  );
}

function* loadInvitedCompanies(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_invitedCompaniesStateChanged, () =>
    api.portfolioMonitoring.getPortfolioInvites(companyId)
  );
}

function* bulkInvestorInvites(companyId: number, fileId: number) {
  const api: API = yield getAPI();
  yield request(PM_invitedCompaniesStateChanged, () =>
    api.portfolioMonitoring.bulkInvestorInvites(companyId, fileId)
  );
}

function* handleCompanyInviteList() {
  yield takeRequestTrigger(
    [
      PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_USED,
      PortfolioMonitoringActionType.PM_COMPANY_INVITE_RESPONSE_SENT,
    ],
    function* ({ payload: { companyId } }: PM_CompanyInviteListUsed) {
      yield call(loadPendingInvites, companyId);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_HOOK_USED],
    function* ({ payload: { companyId } }: PM_ShowOnboardingHookUsed) {
      yield call(loadShowOnboarding, companyId);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_DASHBOARD_HOOK_USED],
    function* ({ payload: { companyIds } }: PM_DashboardHookUsed) {
      yield call(loadDashboard, companyIds);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_HOOK_USED],
    function* ({ payload: { companyId } }: PM_ConnectorsStatusHookUsed) {
      yield call(loadConnectorsStatus, companyId);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_HOOK_USED],
    function* ({
      payload: { companyId },
    }: PM_PortfolioCompanyInviteUrlHookUsed) {
      yield call(loadPortfolioCompanies, companyId);
    }
  );

  yield takeRequestTrigger(
    [PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_HOOK_USED],
    function* ({
      payload: { companyId },
    }: PM_PortfolioCompanyInviteUrlHookUsed) {
      yield call(loadPortfolioCompanyInviteUrl, companyId);
    }
  );
}

function* loadPendingInvites(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_companyInviteListStateChanged, () =>
    api.portfolioMonitoring.getCompanyPortfolioInvites(companyId)
  );
}

function* loadShowOnboarding(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_showOnboardingStateChanged, () =>
    api.portfolioMonitoring.getPortfolioShowOnboarding(companyId)
  );
}

function* loadDashboard(companyIds: number[]) {
  const api: API = yield getAPI();
  yield request(PM_dashboardStateChanged, () =>
    api.portfolioMonitoring.getPortfolioDashboard(companyIds)
  );
}

function* loadConnectorsStatus(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_connectorsStatusChanged, () =>
    api.portfolioMonitoring.getPMConnectorsStatus(companyId)
  );
}

function* loadPortfolioCompanyInviteUrl(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_portfolioCompanyInviteUrlStateChanged, () =>
    api.portfolioMonitoring.getPortfolioCompanyInviteUrl(companyId)
  );
}

function* loadPortfolioCompanies(companyId: number) {
  const api: API = yield getAPI();
  yield request(PM_portfolioCompaniesStateChanged, () =>
    api.legacy.getPortfolioCompanies(companyId)
  );
}
