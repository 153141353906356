import { getContext } from 'redux-saga/effects';

export const API_CONTEXT_NS = 'api';
export const HISTORY_CONTEXT_NS = 'history';

export function getAPI() {
  return getContext(API_CONTEXT_NS);
}

export function getHistory() {
  return getContext(HISTORY_CONTEXT_NS);
}
