import { datadogRum } from '@datadog/browser-rum';
import { User } from '@hum/types';

export function setDataDogUser(user: User) {
  if (process.env.NODE_ENV === 'production') {
    datadogRum.setUser({
      ...user,
      id: user.id.toString(),
      name: `${user.firstName} ${user.lastName}`,
    });
  }
}
