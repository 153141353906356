import { parse } from 'papaparse';

// This returns only the first row of the CSV file
// Used for data mapping and data labeling, it's fast because it aborts work after
// the 1st row is processed
export const getCSVColumns = (url: string) =>
  new Promise<string[]>((resolve, reject) => {
    parse(url, {
      download: true,
      header: true,
      step: (row, parser) => {
        resolve(row.meta.fields);
        parser.abort();
      },
      error: (parserError) => {
        const error = new Error(`Could not parse ${url}`);

        // @ts-ignore
        error.parserError = parserError;

        reject(error);
      },
    });
  });

export const parseCSV = (url: string) =>
  new Promise<{ data: any[] }>((resolve, reject) => {
    parse(url, {
      download: true,
      header: true,
      complete: (result) => {
        resolve(result);
      },
      error: (e) => {
        reject(e);
      },
    });
  });
