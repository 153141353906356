import { put, takeEvery, fork, cancel, select } from 'redux-saga/effects';
import * as URL from 'url';
import { routeMatches, AppState } from '../../state';
import { routerUrlPushed, HistoryActionType } from '../../actions';
import { HistoryState } from '@hum/common/src/ducks/state/history';
import { History } from 'history';
import { getHistory } from '.';

export function takeRequestTrigger<TActionType extends string>(
  actionType:
    | TActionType
    | (((action: any) => boolean) | string)[]
    | ((action: any) => boolean),
  fn: (...args: any[]) => any
) {
  let task: any;

  return takeEvery(actionType, function* (action) {
    // Cancel the task so that we don't deal with race conditions that may happen
    // when navigating between pages.
    if (task) {
      yield cancel(task);
    }
    task = yield fork(fn, action);
  });
}
export function* redirectWindow(info: Partial<HistoryState>) {
  const history: History = yield getHistory();
  const { history: historyState }: AppState = yield select();
  const newState = {
    ...historyState,
    ...info,
  };

  history?.push(URL.format(newState));

  yield put(routerUrlPushed(info));
}

export const route = (routePath: string) => {
  // repl /path/:param -> /path/[^\/]+
  const testRoute = routeMatches(routePath);
  return (action: any) => {
    if (
      action.type !== HistoryActionType.HISTORY_INITIALIZED &&
      action.type !== HistoryActionType.ROUTER_URL_PUSHED &&
      action.type !== HistoryActionType.HISTORY_CHANGED
    ) {
      return false;
    }

    return Boolean(
      action.payload.pathname && testRoute(action.payload.pathname)
    );
  };
};
