import { parse as parseQueryString } from 'query-string';

type OAuthWindowOptions = {
  width?: number;
  height?: number;
  title?: string;
};

type CodeHandler = (code: string, queryParams: Record<string, string>) => void;
type CloseHandler = () => void;
const POLL_TIMEOUT = 50;

/**
 * @Deprecated use openOAUthWindow2 instead
 */
export const openOAUthWindow = (
  url: string,
  onCode: CodeHandler,
  onClose?: CloseHandler,
  { width = 500, height = 500, title }: OAuthWindowOptions = {}
) => {
  const features = [
    `width=${width}`,
    `height=${height}`,
    `left=${window.screenX + (window.outerWidth - width) / 2}`,
    `top=${window.screenY + (window.outerHeight - height) / 2.5}`,
  ].join(',');

  const popup = window.open(url, title, features);

  const interval = setInterval(() => {
    let params: Record<string, string> | null = null;
    let code;

    try {
      params = parseQueryString(popup?.location.search || '') as Record<
        string,
        string
      >;
      code = params.code;
    } catch (e) {
      console.debug(e);
      // ignore access errors, for they are expected
    }

    if (code && params) {
      clearInterval(interval);
      onCode(code, params);
      popup!.close();
    } else if (!popup || popup.closed) {
      clearInterval(interval);
      if (onClose) {
        onClose();
      }
    }
  }, POLL_TIMEOUT);

  return {
    dispose() {
      popup!.close();
    },
  };
};

export const openOAUthWindow2 = async (
  url: string,
  { width = 500, height = 500, title }: OAuthWindowOptions = {}
) => {
  return new Promise<Record<string, string>>((resolve, reject) => {
    const features = [
      `width=${width}`,
      `height=${height}`,
      `left=${window.screenX + (window.outerWidth - width) / 2}`,
      `top=${window.screenY + (window.outerHeight - height) / 2.5}`,
    ].join(',');

    const popup = window.open(url, title, features);

    const interval = setInterval(() => {
      let params: Record<string, string> | null = null;

      try {
        params = parseQueryString(popup?.location.search || '') as any;
      } catch (e) {
        // ignore access errors, for they are expected
        console.error('Error retrieving params from popup');
        console.error(e);
      }

      if (params && params.code) {
        clearInterval(interval);
        resolve(params);
        popup!.close();
      } else if (!popup || popup.closed) {
        clearInterval(interval);

        if (!params) {
          reject();
        }
      }
    }, POLL_TIMEOUT);
  });
};
