import memoize from 'fast-memoize';
import * as qs from 'query-string';
import { Routes } from './routes';
import { History } from 'history';
export const routeMatches = memoize((route: string) => {
  const routeRegExp = new RegExp(`^${route.replace(/\:[^\/]+/g, '[^/]+')}/*$`);

  return (pathname: string) => Boolean(pathname && routeRegExp.test(pathname));
});

type RouteOptions = {
  params?: Record<string, any>;
  query?: Record<string, string>;
};

export const getPathname = (route: string, { query }: RouteOptions = {}) => {
  // TODO - replace params with options.params
  return (
    route +
    (query && Object.keys(query).length ? '?' + qs.stringify(query) : '')
  );
};

export const getPathnameParams = (
  pathname: string,
  routes: Record<string, string>
) => {
  for (const key in routes) {
    const route = routes[key];
    if (routeMatches(route)(pathname)) {
      return (
        pathname.match(new RegExp(route.replace(/\:([^\/]+)/g, '(?<$1>[^/]+)')))
          ?.groups || {}
      );
    }
  }

  return {};
};

export const createUrlFromTemplate = (
  urlTemplate: string,
  params: Record<string, string>,
  query: Record<string, string> = {}
) => {
  const paramNames = urlTemplate.match(/\:\w+/g) || [];

  let url = urlTemplate;

  for (const paramName of paramNames) {
    const keyName = paramName.substr(1);
    const paramValue = params[keyName];
    if (paramValue == null) {
      throw new Error(`Param "${keyName}" is missing from params`);
    }
    url = url.replace(paramName, paramValue);
  }

  if (Object.keys(query).length) {
    url = url + '?' + qs.stringify(query);
  }

  return url;
};

export const createSyndicationInvestorMatchRoute = (uuid: string) => {
  return `${Routes.SYNDICATION_INVESTOR_MATCH}?uuid=${uuid}`;
};

export const redirectHandler = memoize(
  (pathname: string, history: History) => () => {
    history.push(pathname + history.location.search);
  }
);
