import { call, fork, select } from 'redux-saga/effects';
import {
  takeRequestTrigger,
  route,
  loadCurrentCompany,
  withSession,
  getAPI,
} from './utils';
import { request } from '@hum/common/src/ducks/sagas/util';
import {
  ActionType,
  apiBrandingRequestStateChanged,
  BrandingEditorChanged,
  BrandingHookUsed,
} from '../actions';
import { AppState, getUserCompanyId, Routes } from '../state';
import { API } from '../backend/api';

export function* mainBrandingSaga() {
  yield fork(handleHook);
  yield fork(handleBrandingChanges);
}

function* handleHook() {
  yield takeRequestTrigger(
    route(Routes.BRANDING),
    withSession(function* () {
      const api: API = yield getAPI();
      const result = yield call(loadCurrentCompany);
      yield request(apiBrandingRequestStateChanged, async () => {
        return api.legacy.getBranding(result.data?.id);
      });
    })
  );
  yield takeRequestTrigger(
    [ActionType.BRANDING_HOOK_USED],
    function* ({ payload: { companyId } }: BrandingHookUsed) {
      const api: API = yield getAPI();
      yield request(apiBrandingRequestStateChanged, async () => {
        return api.legacy.getBranding(companyId);
      });
    }
  );
}

function* handleBrandingChanges() {
  yield takeRequestTrigger(
    ActionType.BRANDING_EDITOR_CHANGED,
    function* ({ payload: { branding } }: BrandingEditorChanged) {
      const state: AppState = yield select();
      const api: API = yield getAPI();
      yield request(apiBrandingRequestStateChanged, async () => {
        return await api.legacy.setBranding(getUserCompanyId(state), branding);
      });
    }
  );
}
