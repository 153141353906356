import { fork } from 'redux-saga/effects';

import { handleV4Onboarding } from '@hum/icm-app/src/pages/onboarding-v4/ducks/sagas';
import { handleV5Onboarding } from '@hum/icm-app/src/pages/onboarding-v5/ducks/sagas';
import { handleMetrics } from '@hum/icm-app/src/pages/onboarding-v5/metrics/sagas';
import {
  handleAdmin,
  handleManageUsers,
} from '@hum/icm-app/src/pages/admin/ducks/sagas';
import { handleCompanyDetail } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/sagas';
import { analyticsSaga } from '@hum/icm-app/src/modules/analytics/ducks';

import { trackGeneralAppEvents } from './analytics';
import { appSaga } from './app';
import { handleHistory } from './history';
import { docusignSaga } from './docusign';
import { syndicationSaga } from './syndication';
import { handleRoutes } from './routes';
import { authSaga } from './auth';
import { onboardingSaga } from './onboarding';
import { principalInvestmentSaga } from './principal-investment';
import { portfolioMonitoringSaga } from './portfolio-monitoring';
import { adminSaga } from './admin';
import { handleCompanyDashboard } from './companyDashboard';
import { handleV5SignupSaga } from './signupv5';
import { handleAdminAnalytics } from '@hum/icm-app/src/pages/admin/AdminAnalytics/ducks/sagas';
import { handleCreditBox } from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/ducks/sagas';

/**
 * Main entry point for all side-effect action handlers, including
 * abstract sagas that aren't directly accessible in app code.
 */

export function* rootSaga() {
  yield fork(analyticsSaga);
  yield fork(appSaga);
  yield fork(handleHistory);
  yield fork(docusignSaga);
  yield fork(syndicationSaga);
  yield fork(handleRoutes);
  yield fork(authSaga);
  yield fork(onboardingSaga);
  yield fork(principalInvestmentSaga);
  yield fork(portfolioMonitoringSaga);
  yield fork(handleV4Onboarding);
  yield fork(handleV5Onboarding);
  yield fork(handleMetrics);
  yield fork(handleAdmin);
  yield fork(handleManageUsers);
  yield fork(analyticsSaga);
  yield fork(trackGeneralAppEvents);
  yield fork(adminSaga);
  yield fork(handleCompanyDetail);
  yield fork(handleV5SignupSaga);
  yield fork(handleAdminAnalytics);
  yield fork(handleCompanyDashboard);
  yield fork(handleCreditBox);
}
