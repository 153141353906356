import { parse } from 'url';
import * as H from 'history';
import qs from 'query-string';
import { env } from '@hum/common';

export const isStaging = env.NEXT_STATIC_CAPITAL_ENV === 'staging';
export const isProduction = env.NEXT_STATIC_CAPITAL_ENV === 'production';
export const isDemo = env.NEXT_STATIC_CAPITAL_ENV === 'demo';
export const isDevelopment = !isStaging && !isProduction && !isDemo;

const ICM_SUBDOMAIN = 'icm';

export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/gm;

export const getSubdomain = () => {
  const developmentHostsRegex = /(localhost|frontend\-e2e)/;
  // Get subdomain from query string (localhost) or from actual subdomain (stg, prod)
  const subdomain = developmentHostsRegex.test(window.location.hostname)
    ? (parse(window.location.href, true).query.subdomain as string)
    : window.location.host.split('.')[0];

  const isLocalhost = developmentHostsRegex.test(window.location.hostname);
  const isPrimarySubdomain = ICM_SUBDOMAIN === subdomain;

  return {
    subdomain,
    isPrimarySubdomain,
    isLocalhost,
    isStaging,
    isProduction,
    isDevelopment,
    isDemo,
  };
};

export const stripConnectFromPathname = (pathname: string) =>
  pathname.includes('company-activation/connectors')
    ? pathname.replace(/\/connect$/g, '')
    : pathname;

/* expandConnectUrl
 * Adds origin and pathname as query parameters to the link generated by codat,
 * which can be used to identify which page within-app a user should be
 * redirected back to.
 */
export const expandConnectUrl = (connectUrl: string): string => {
  const pathname = stripConnectFromPathname(window.location.pathname);

  const query = {
    ...qs.parse(window.location.search),
    origin: window.location.origin,
    pathname,
  };

  return `${connectUrl}?${qs.stringify(query)}`;
};

export type ExtractedParams = {
  pathname: string;
  params: Record<string, any>;
};

/* extractCodatParams
 * Given a location, inspect that locations query string for `pathname` and
 * return a path that can be passed to `history.replace` which doesn't include
 * pathname in the query string.
 */
export const extractCodatParams = (
  extracted: ExtractedParams
): ExtractedParams => {
  const { pathname: oldPathname, params } = extracted;
  const { company_id, pathname, ...remainingParams } = params;

  if (company_id) {
    Object.assign(remainingParams, {
      company_id,
    });
  }
  return {
    pathname: (pathname as string) || oldPathname,
    params: remainingParams,
  };
};

export const extractOAuthParams = (
  extracted: ExtractedParams,
  isDirectConnector: boolean = false
): ExtractedParams => {
  const { params, pathname } = extracted;
  const { code, scope, session_state, state, ...remainingParams } = params;

  return {
    pathname:
      isDirectConnector || code || scope || session_state || state
        ? stripConnectFromPathname(pathname)
        : pathname,
    params: remainingParams,
  };
};

export const extractAuthParams = (
  location: H.Location,
  isDirectConnector: boolean = false
): string => {
  // remove the error message
  const { error_message, ...queryParams } = qs.parse(location.search);
  const { pathname, params } = extractOAuthParams(
    extractCodatParams({
      pathname: location.pathname,
      params: queryParams,
    }),
    isDirectConnector
  );

  const search = Object.keys(params).length ? `?${qs.stringify(params)}` : '';
  return `${pathname}${search}`;
};
